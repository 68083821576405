import React from 'react';

import { Button } from 'primereact/button';

import './style.css';
import CustomInputNumber from '../CustomInputNumber';
import CustomInputText from '../CustomInputText';
import CustomDropdown from '../CustomDropdown';

interface IPaginator {
    page: number;
    rows: number;
    rowsPerPageOptions?: number[];
    onPageChange(event: { page: number; rows: number }): void;
    disableNext?: boolean;
    style?: React.CSSProperties;
    maxPages?: number;
    showRefresh?: boolean;
    onRefresh?(): void;
    isRefreshing?: boolean;
}

const NoTotalPaginator: React.FC<IPaginator> = ({
    page,
    rows,
    onPageChange,
    rowsPerPageOptions = [10, 20, 50],
    disableNext,
    style,
    maxPages,
    showRefresh,
    onRefresh,
    isRefreshing,
}) => {
    return (
        <div className='no-total-paginator-wrap' style={style}>
            <div className='page-buttons'>
                <span className='p-buttonset'>
                    <Button
                        icon='pi pi-angle-double-left'
                        disabled={page === 1}
                        onClick={() => onPageChange({ page: 1, rows })}
                    />
                    <Button
                        label='Prev.'
                        icon='pi pi-angle-left'
                        disabled={page === 1}
                        onClick={() => onPageChange({ page: page - 1, rows })}
                    />
                    <CustomInputNumber
                        format={false}
                        value={page}
                        inputStyle={{ borderRadius: 0, margin: '0 1px 0 1px' }}
                        onValueChange={(e) =>
                            onPageChange({
                                page:
                                    e.target.value == null ? 1 : e.target.value,
                                rows,
                            })
                        }
                        size={1}
                        min={1}
                    />
                    {maxPages && (
                        <CustomInputText
                            value={`of ${maxPages}`}
                            style={{
                                borderRadius: 0,
                                margin: '0 0 0 -1px',
                            }}
                            size={2}
                            disabled
                        />
                    )}
                    <Button
                        label='Next'
                        icon='pi pi-angle-right'
                        iconPos='right'
                        disabled={
                            disableNext || (!!maxPages && page >= maxPages)
                        }
                        onClick={() => onPageChange({ page: page + 1, rows })}
                        style={{ marginRight: !maxPages ? '8px' : '0' }}
                    />
                    {maxPages && (
                        <Button
                            icon='pi pi-angle-double-right'
                            disabled={page >= maxPages}
                            onClick={() =>
                                onPageChange({ page: maxPages, rows })
                            }
                            style={{ marginRight: '8px' }}
                        />
                    )}
                </span>
            </div>
            <CustomDropdown
                value={rows}
                options={rowsPerPageOptions}
                onChange={(e) => onPageChange({ page, rows: e.value })}
            />
            {showRefresh && (
                <Button
                    icon='pi pi-refresh'
                    label='Refresh'
                    loading={isRefreshing}
                    onClick={onRefresh}
                    style={{ marginLeft: '8px' }}
                />
            )}
        </div>
    );
};

export default NoTotalPaginator;