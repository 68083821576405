import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Badge, BadgeProps } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
    DataTable,
    DataTableFilterMeta,
    DataTableSortMeta,
} from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import DateBadge from 'components/Misc/DateBadge';
import NoTotalPaginator from 'components/Form/NoTotalPaginator';

import { useProjectQuery } from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages';
import {
    exportLinksWithVideoInfo,
    getLinksFromProject,
} from 'services/title-report/link';
import { Tooltip } from 'primereact/tooltip';
import RowDataView from 'components/Misc/RowDataView';
import { DataTableOperatorFilterMetaData } from 'primereact/datatable';
import downloadFromUrl from 'utils/downloadFromUrl';
import { getOneVideo } from 'services/videoid/video';
import OidColumn from 'components/Misc/OidColumn';

const ListVideoMatches = () => {
    const toastRef = useRef<Toast>(null);

    const params = useParams<{
        projectOid: string;
    }>() as {
        projectOid: string;
    };

    const projectQuery = useProjectQuery();
    const queryClient = useQueryClient();

    const [pageData, setPageData] = useState({
        page: 1,
        rows: 10,
    });
    const [sort, setSort] = useState<{
        sortField: undefined | string;
        sortOrder: DataTableSortMeta['order'] | null;
    }>({
        sortField: 'videoid_data.checked_at',
        sortOrder: -1,
    });
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        '_id': {
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                },
            ],
            operator: FilterOperator.AND,
        },
        'titleinfo.slug': {
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                },
            ],
            operator: FilterOperator.AND,
        },
        'videoid_data.validation.is_valid': {
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                },
            ],
            operator: FilterOperator.AND,
        },
        'videoid_data.is_match': {
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                },
            ],
            operator: FilterOperator.AND,
        },
        videoid_data_validation_status: {
            constraints: [
                {
                    value: 'done',
                    matchMode: FilterMatchMode.EQUALS,
                },
            ],
            operator: FilterOperator.AND,
        },
        'videoid_data.validation.checked_at': {
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.DATE_AFTER,
                },
            ],
            operator: FilterOperator.AND,
        },
    });

    const handleFilters = () => {
        const newFilters = {
            ...filters,
        };
        delete newFilters['videoid_data_validation_status'];

        const validationStatusValue = (
            filters.videoid_data_validation_status as DataTableOperatorFilterMetaData
        ).constraints[0].value;
        newFilters['videoid_data.validation.status'] = {
            constraints: [
                {
                    value:
                        validationStatusValue == null
                            ? 'pending'
                            : validationStatusValue === 'done'
                            ? 'done'
                            : 'sent,queued',
                    matchMode:
                        validationStatusValue == null
                            ? FilterMatchMode.NOT_EQUALS
                            : FilterMatchMode.EQUALS,
                },
            ],
            operator: FilterOperator.AND,
        };
        return newFilters;
    };

    const videosLinkQuery = useQuery<Ether.TitlesReport.ILink[], Error>(
        ['list-link-videos', pageData, filters, sort],
        async (): Promise<Ether.TitlesReport.ILink[]> => {
            const newFilters = handleFilters();
            return getLinksFromProject(params.projectOid, {
                limit: pageData.rows,
                offset: (pageData.page - 1) * pageData.rows,
                filters: newFilters,
                ...sort,
            });
        }
    );

    const renderStatus = (rowData: Ether.TitlesReport.ILink) => {
        let severity: BadgeProps['severity'] = 'info';

        switch (rowData.videoid_data.validation.status) {
            case 'done':
                severity = 'success';
                break;
            default:
                severity = 'info';
                break;
        }

        return (
            <Badge
                value={rowData.videoid_data.validation.status}
                severity={severity}
            />
        );
    };

    const exportCsvMutation = useMutation(() =>
        exportLinksWithVideoInfo(params.projectOid, {
            filters: handleFilters(),
        })
    );

    const numberToPercentage = (value: number | undefined) => {
        if (value == null) return '-';
        const percentage = value * 100;
        const roundedPercentage = Math.round(percentage * 100) / 100;
        const formattedPercentage = roundedPercentage
            .toFixed(2)
            .replace('/.?0+$/', '');
        return formattedPercentage + '%';
    };

    const [selectedLink, setSelectedLink] =
        useState<Ether.TitlesReport.ILink | null>(null);

    const renderActionButtons = (rowData: Ether.TitlesReport.ILink) => {
        const filesUri = window.EXTERNAL_URI_FILE;
        return (
            <div style={{ display: 'flex', gap: '8px' }}>
                <Button
                    icon='pi pi-search'
                    className='p-button-outlined'
                    onClick={() => setSelectedLink(rowData)}
                />
                <Button
                    icon='pi pi-download'
                    className='p-button-outlined'
                    tooltip='Download new'
                    label='New'
                    onClick={() =>
                        downloadFromUrl(
                            `${filesUri}/download/${
                                rowData.videoid_data.video_file_oid
                            }?t=${Date.now()}`
                        )
                    }
                />
                <Button
                    icon='pi pi-download'
                    className='p-button-outlined'
                    tooltip='Download reference'
                    label='Ref.'
                    onClick={async () => {
                        const related_oid =
                            rowData.videoid_data.match_data?.video_oid;
                        if (!related_oid) return;
                        const sample_video = await queryClient.fetchQuery(
                            ['sample_video', related_oid],
                            () => getOneVideo(related_oid)
                        );
                        downloadFromUrl(
                            `${filesUri}/download/${
                                sample_video?.video_file_oid
                            }?t=${Date.now()}`
                        );
                    }}
                />
            </div>
        );
    };

    const getValidEvaluation = (rowData: Ether.TitlesReport.ILink) => {
        if (rowData.videoid_data.validation.is_valid == null) return null;
        const { is_match } = rowData.videoid_data;
        const { is_valid } = rowData.videoid_data.validation;
        if (is_match) return is_valid ? 'true positive' : 'false positive';
        return is_valid ? 'true negative' : 'false negative';
    };

    return (
        <div>
            <RowDataView
                onHide={() => setSelectedLink(null)}
                visible={!!selectedLink}
                modelData={selectedLink}
            />
            <Toast ref={toastRef} />
            {projectQuery?.data && (
                <>
                    <h2>{projectQuery?.data.name} - VideoID Matches</h2>
                    <Button
                        outlined
                        label='Export CSV'
                        icon='pi pi-download'
                        onClick={() => exportCsvMutation.mutate()}
                        loading={exportCsvMutation.isLoading}
                    />
                    <NoTotalPaginator
                        page={pageData.page}
                        rows={pageData.rows}
                        onPageChange={setPageData}
                        disableNext={
                            !videosLinkQuery.data ||
                            videosLinkQuery.data.length < pageData.rows
                        }
                    />
                    {videosLinkQuery.error ? (
                        videosLinkQuery.error.message
                    ) : (
                        <DataTable
                            value={videosLinkQuery.data}
                            loading={videosLinkQuery.isLoading}
                            emptyMessage='No match data available'
                            filters={filters}
                            onFilter={(e) => setFilters(e.filters)}
                            onSort={(e) =>
                                setSort({
                                    sortField: e.sortField,
                                    sortOrder: e.sortOrder,
                                })
                            }
                            sortField={sort.sortField}
                            sortOrder={sort.sortOrder}
                            removableSort
                            lazy
                        >
                            {OidColumn()}
                            <Column
                                field='titleinfo.slug'
                                header='Slug'
                                filter
                                showFilterOperator={false}
                                showAddButton={false}
                            />
                            <Column
                                field='videoid_data.match_data.score'
                                header='Match score'
                                body={(rowData) => (
                                    <>
                                        <Tooltip
                                            target='.match-data-score'
                                            autoHide={false}
                                        />
                                        <Badge
                                            className='match-data-score'
                                            data-pr-tooltip={
                                                rowData.videoid_data.match_data
                                                    ?.score as undefined
                                            }
                                            severity='info'
                                            value={numberToPercentage(
                                                rowData.videoid_data.match_data
                                                    ?.score
                                            )}
                                        />
                                    </>
                                )}
                                sortable
                            />
                            <Column
                                field='videoid_data.is_match'
                                header='Is match?'
                                body={(rowData) => (
                                    <Badge
                                        severity={
                                            rowData.videoid_data.is_match !=
                                            null
                                                ? rowData.videoid_data.is_match
                                                    ? 'success'
                                                    : 'danger'
                                                : undefined
                                        }
                                        value={
                                            rowData.videoid_data.is_match !=
                                            null
                                                ? rowData.videoid_data.is_match
                                                    ? 'Yes'
                                                    : 'No'
                                                : '-'
                                        }
                                    />
                                )}
                                filter
                                filterElement={(options) => (
                                    <Dropdown
                                        placeholder='Select is match'
                                        value={options.value}
                                        onChange={(e) =>
                                            options.filterCallback(
                                                e.target.value
                                            )
                                        }
                                        options={[
                                            {
                                                label: 'Yes',
                                                value: true,
                                            },
                                            {
                                                label: 'No',
                                                value: false,
                                            },
                                        ]}
                                    />
                                )}
                                showFilterOperator={false}
                                showFilterMenuOptions={false}
                                showAddButton={false}
                            />
                            <Column
                                field='videoid_data.validation.is_valid'
                                header='Is valid?'
                                body={(rowData) => {
                                    const isValid =
                                        rowData.videoid_data.validation
                                            .is_valid;
                                    return (
                                        <Badge
                                            severity={
                                                isValid != null
                                                    ? isValid
                                                        ? 'success'
                                                        : 'danger'
                                                    : undefined
                                            }
                                            value={
                                                isValid != null
                                                    ? isValid
                                                        ? 'Valid'
                                                        : 'Not valid'
                                                    : '-'
                                            }
                                        />
                                    );
                                }}
                                filter
                                filterElement={(options) => (
                                    <Dropdown
                                        placeholder='Select is valid'
                                        value={options.value}
                                        onChange={(e) =>
                                            options.filterCallback(
                                                e.target.value
                                            )
                                        }
                                        options={[
                                            {
                                                label: 'Valid',
                                                value: true,
                                            },
                                            {
                                                label: 'Not valid',
                                                value: false,
                                            },
                                        ]}
                                    />
                                )}
                                showFilterOperator={false}
                                showFilterMenuOptions={false}
                                showAddButton={false}
                            />
                            <Column
                                field='videoid_data_validation_status'
                                header='Validation Status'
                                body={renderStatus}
                                filter
                                filterElement={(options) => (
                                    <Dropdown
                                        placeholder='Select status'
                                        value={options.value}
                                        onChange={(e) =>
                                            options.filterCallback(
                                                e.target.value
                                            )
                                        }
                                        options={[
                                            {
                                                label: 'Done',
                                                value: 'done',
                                            },
                                            {
                                                label: 'Not done',
                                                value: 'pending',
                                            },
                                        ]}
                                    />
                                )}
                                showFilterOperator={false}
                                showFilterMenuOptions={false}
                                showAddButton={false}
                            />
                            <Column
                                field='validation_result'
                                header='Validation Result'
                                body={(rowData) => {
                                    const res = getValidEvaluation(rowData);
                                    return res ?? <Badge value='-' />;
                                }}
                            />
                            <Column
                                field='videoid_data.validation.checked_at'
                                header='Validated at'
                                body={(rowData) => {
                                    const checked_at =
                                        rowData?.videoid_data?.validation
                                            ?.checked_at;
                                    if (!checked_at) return <Badge value='-' />;
                                    return (
                                        <DateBadge
                                            value={
                                                rowData.videoid_data?.validation
                                                    ?.checked_at
                                            }
                                        />
                                    );
                                }}
                                filter
                                showFilterOperator={false}
                                showAddButton={false}
                                filterType='date'
                                dataType='date'
                                sortable
                            />
                            <Column
                                header='Actions'
                                body={renderActionButtons}
                            />
                        </DataTable>
                    )}
                </>
            )}
        </div>
    );
};

export default ListVideoMatches;
