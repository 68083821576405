import CustomDropdown from 'components/Form/CustomDropdown';
import { useQuery } from '@tanstack/react-query';
import { getProjectTitles } from 'services/title-report/titles';

interface ITitlesFilters {
    projectId?: string;
    value: string;
    onChange(value: string): void;
}

const parseTitleList = (
    titles: Ether.TitlesReport.ITitleSetup[]
): {
    label: string;
    value: string;
}[] => {
    const sortedTitles = titles.sort((a, b) => {
        if (a.titledb_slug > b.titledb_slug) return 1;
        if (a.titledb_slug < b.titledb_slug) return -1;
        return 0;
    });
    const trackedTitles = sortedTitles.filter(
        (title) => title.clients_setup[0].is_tracked === true
    );
    const untrackedTitles = sortedTitles.filter(
        (title) => title.clients_setup[0].is_tracked === false
    );
    return [
        {
            label: '--- Any title ---',
            value: '',
        },
        {
            label: '--- Any tracked ---',
            value: trackedTitles.map((title) => title.titledb_slug).join(','),
        },
        {
            label: '--- Any not tracked ---',
            value: untrackedTitles.map((title) => title.titledb_slug).join(','),
        },
        ...trackedTitles.map((title) => ({
            label: title.titledb_slug,
            value: title.titledb_slug,
        })),
        ...untrackedTitles.map((title) => ({
            label: '[NOT TRACKED] ' + title.titledb_slug,
            value: title.titledb_slug,
        })),
    ];
};

const TitlesFilter: React.FC<ITitlesFilters> = ({
    projectId,
    value,
    onChange,
}) => {
    const titlesQuery = useQuery(
        ['get-titles-config', projectId],
        async ({ signal }): Promise<Ether.TitlesReport.ITitleSetup[]> => {
            if (!projectId) throw new Error();
            return await getProjectTitles(projectId, {
                signal,
            });
        },
        {
            enabled: !!projectId,
        }
    );

    return (
        <CustomDropdown
            label='Title'
            placeholder={
                titlesQuery.isLoading
                    ? 'Loading titles...'
                    : titlesQuery.isError
                    ? 'Error getting titles'
                    : 'Select a title'
            }
            value={value}
            onChange={(e) => onChange(e.value)}
            loading={titlesQuery.isLoading}
            options={titlesQuery.data ? parseTitleList(titlesQuery.data) : []}
            disabled={titlesQuery.isLoading || titlesQuery.isError}
        />
    );
};

export default TitlesFilter;
