import { useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import LoadingSpinner from 'components/Misc/LoadingSpinner';
import {
    getNextLinkToValidateVideoID,
    validateVideoIdCheck,
} from 'services/title-report/link';

import MatchValidation from './components/MatchValidation';

import { getOneVideo } from 'services/videoid/video';
import TitlesFilter from '../components/TitlesFilter';
import CustomInputNumber from 'components/Form/CustomInputNumber';

import './style.css';
import CacheControl from 'controller/cache/cacheController';
import { useProjectQuery } from '..';

interface IVideoIDFilters {
    titledb_slug: string;
    match_min_score: number | null;
    match_max_score: number | null;
}

interface IAppliedLinkFilters extends IVideoIDFilters {
    isApplied: boolean;
}

const ValidateVideoId = () => {
    const toastRef = useRef<Toast>(null);
    const params = useParams();

    const queryClient = useQueryClient();

    const projectQuery = useProjectQuery();

    const [videoSources, setVideoSources] = useState({
        original_source: '',
        sample_source: '',
        done: false,
    });

    const [filters, setFilters] = useState<IVideoIDFilters>({
        titledb_slug: '',
        match_min_score: null,
        match_max_score: null,
    });

    const [appliedFilters, setAppliedFilters] = useState<IAppliedLinkFilters>({
        isApplied: false,
        titledb_slug: '',
        match_min_score: null,
        match_max_score: null,
    });

    const linkQuery = useQuery<Ether.TitlesReport.ILink | null, Error>(
        ['get-videoid-matches-from-project', params.projectOid, appliedFilters],
        async (): Promise<Ether.TitlesReport.ILink | null> => {
            if (!params.projectOid) throw new Error('no project oid supplied');
            const links = CacheControl.VideoIDLink.get();
            if (links) {
                const foundLink = links.find((link) => {
                    const score = link.videoid_data.match_data?.score as number;
                    const slug = link.titleinfo?.slug as string;
                    const { titledb_slug, match_min_score, match_max_score } =
                        appliedFilters;
                    let conditional = true;
                    if (titledb_slug !== '')
                        conditional =
                            conditional && titledb_slug.includes(slug);
                    if (match_min_score)
                        conditional = conditional && score >= match_min_score;
                    if (match_max_score)
                        conditional = conditional && score <= match_max_score;
                    return conditional;
                });
                console.log(foundLink);
                if (foundLink) return foundLink;
            }
            const link = await getNextLinkToValidateVideoID(params.projectOid, {
                filters: {
                    titledb_slug:
                        appliedFilters.titledb_slug !== ''
                            ? appliedFilters.titledb_slug
                            : undefined,
                    videoid_min_score:
                        appliedFilters.match_min_score ?? undefined,
                    videoid_max_score:
                        appliedFilters.match_max_score ?? undefined,
                },
            });
            if (!link) return null;
            CacheControl.VideoIDLink.save(link);
            return link;
        },
        {
            enabled: projectQuery?.isSuccess && appliedFilters.isApplied,
            onSuccess: async (data) => {
                if (!data) return;
                const related_oid = data.videoid_data.match_data?.video_oid;
                if (!related_oid) return;
                const original_video_oid = data.videoid_data.video_file_oid;
                const sample_video = await queryClient.fetchQuery(
                    ['sample_video', related_oid],
                    () => {
                        return getOneVideo(related_oid);
                    }
                );
                if (!sample_video) return;
                const sample_video_oid = sample_video.video_file_oid;
                setVideoSources({
                    original_source: original_video_oid,
                    sample_source: sample_video_oid,
                    done: true,
                });
            },
        }
    );

    const applyFiltersHandler = () => {
        setAppliedFilters({
            isApplied: true,
            ...filters,
        });
    };

    const linkMutation = useMutation<
        boolean,
        Error,
        { link_id: string; is_match: boolean }
    >(
        (params) => {
            return validateVideoIdCheck(params.link_id, params.is_match);
        },
        {
            onSuccess: (_, params) => {
                CacheControl.VideoIDLink.deleteSpecific(params.link_id);
                linkQuery.refetch();
            },
            onError: (err) =>
                toastRef.current?.show({
                    severity: 'error',
                    summary: 'Failed to validate',
                    detail: err.message,
                }),
        }
    );

    const handleValidate = (link_id: string, is_match: boolean) => {
        if (!linkQuery.isSuccess || linkMutation.isLoading) return;
        linkMutation.mutateAsync({
            link_id,
            is_match,
        });
    };

    return (
        <div>
            <Toast ref={toastRef} />
            {projectQuery?.data && (
                <>
                    <h2>
                        {projectQuery?.data.name} - Validate VideoID matches
                    </h2>
                    <Link to='matches'>
                        <Button label='List matches' />
                    </Link>
                    <h3>Filters</h3>
                    <div className='filter-group-videoid'>
                        <TitlesFilter
                            projectId={params.projectOid}
                            onChange={(value) =>
                                setFilters((old) => ({
                                    ...old,
                                    titledb_slug: value,
                                }))
                            }
                            value={filters.titledb_slug}
                        />
                        <CustomInputNumber
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    applyFiltersHandler();
                                }
                            }}
                            label='Match minimum score (inclusive)'
                            onChange={(e) =>
                                setFilters((old) => ({
                                    ...old,
                                    match_min_score: e.value,
                                }))
                            }
                            value={filters.match_min_score}
                            placeholder='Value between 0 and 1'
                            min={0}
                            max={1}
                            mode='decimal'
                            minFractionDigits={16}
                        />
                        <CustomInputNumber
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    applyFiltersHandler();
                                }
                            }}
                            label='Match maximum score (inclusive)'
                            onChange={(e) =>
                                setFilters((old) => ({
                                    ...old,
                                    match_max_score: e.value,
                                }))
                            }
                            value={filters.match_max_score}
                            placeholder='Value between 0 and 1'
                            min={0}
                            max={1}
                            mode='decimal'
                            minFractionDigits={16}
                        />
                    </div>
                    <Button
                        label='Apply filters'
                        onClick={applyFiltersHandler}
                        style={{ marginBottom: '8px' }}
                    />
                    {linkQuery.isInitialLoading ? (
                        <div>
                            <LoadingSpinner /> Loading...
                        </div>
                    ) : linkQuery.isError ? (
                        <p>{linkQuery.error.message}</p>
                    ) : (
                        linkQuery.isSuccess && (
                            <div>
                                {linkQuery.data == null ? (
                                    <>
                                        <h3>No link to validate</h3>
                                        <Button
                                            label='Retry'
                                            onClick={() => linkQuery.refetch()}
                                        />
                                    </>
                                ) : (
                                    <MatchValidation
                                        key={linkQuery.data._id}
                                        link={linkQuery.data}
                                        loading={linkMutation.isLoading}
                                        disabled={linkMutation.isLoading}
                                        onValidate={handleValidate}
                                        originalVideoSource={
                                            videoSources.original_source
                                        }
                                        sampleVideoSource={
                                            videoSources.sample_source
                                        }
                                        videosLoading={!videoSources.done}
                                    />
                                )}
                            </div>
                        )
                    )}
                </>
            )}
        </div>
    );
};

export default ValidateVideoId;
