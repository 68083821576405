import CacheControl from 'controller/cache/cacheController';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { isTokenValid } from 'services/title-report/me';

type FetchStatus = 'loading' | 'error' | 'success' | 'unauthenticated';

interface IAuthData {
    token: string | null;
    user: {
        id: string;
        name: string;
    } | null;
}

interface IUseAuth extends IAuthData {
    status: FetchStatus;
    error: string | null;
    signOut?(): void;
}

const AuthContext = createContext<IUseAuth>({
    token: null,
    user: null,
    status: 'loading',
    error: null,
});

const AuthProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [error, setError] = useState<string | null>(null);
    const [authStatus, setAuthStatus] = useState<FetchStatus>('loading');

    const [authData] = useState<IAuthData>(() => {
        const storageData = CacheControl.Auth.get();
        if (!storageData) {
            return {
                token: null,
                user: null,
            };
        }
        return storageData;
    });

    const validateToken = async (token: string | null) => {
        if (!token) return false;
        return isTokenValid(token);
    };

    const handleError = (message: string) => {
        setError(message);
        setAuthStatus('error');
        CacheControl.Auth.delete();
        delete api.defaults.headers.common['access-token'];
    };

    const signOut = () => {
        setAuthStatus('unauthenticated');
        CacheControl.Auth.delete();
        delete api.defaults.headers.common['access-token'];
    };

    const check = (token: string) => {
        validateToken(token)
            .then((isValid) => {
                if (!isValid) {
                    handleError('API Token is not valid.');
                    return;
                }
                if (token != null)
                    api.defaults.headers.common['access-token'] = token;
                setAuthStatus('success');
            })
            .catch((err) => {
                console.error(err);
                handleError('Unexpected error - ' + err.message);
            });
    };

    useEffect(() => {
        if (!authData) return;
        const hash = searchParams.get('hash');
        if (!hash) {
            if (authData.token == null) {
                setAuthStatus('unauthenticated');
                return;
            }
            check(authData.token);
            return;
        }
        const formData = new FormData();
        formData.append('hash', hash);
        formData.append('app', 'title_report_v2');
        api.post(`${window.EXTERNAL_URI_USERDIRECTORY}/auth_hash/`, formData, {
            withCredentials: false,
        })
            .then(({ data }) => {
                if (data.app_data.token) {
                    const userData: IAuthData = {
                        token: data.app_data.token,
                        user: {
                            id: data.user_id,
                            name: data.first_name,
                        },
                    };
                    check(data.app_data.token);
                    CacheControl.Auth.save(userData);
                    setSearchParams({});
                } else {
                    handleError(
                        'No token received. Verify with an admin if you have a token for this application (title_report_v2) on the User Directory.'
                    );
                }
            })
            .catch(() => {
                handleError('Failed to validate hash on UserDirectory.');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authData]);

    return (
        <AuthContext.Provider
            value={{ ...authData, status: authStatus, error, signOut }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);
    return context;
};

export { AuthProvider, useAuth };
