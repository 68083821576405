import { FilterMatchMode } from 'primereact/api';
import { DataTableFilterMetaData } from 'primereact/datatable';

export const handleFilterMatchMode = (
    key: string,
    value: string,
    mode: DataTableFilterMetaData['matchMode']
) => {
    if (value == null) return {};
    switch (mode) {
        case FilterMatchMode.STARTS_WITH:
            return { [key + '|regex']: '^' + value };
        case FilterMatchMode.CONTAINS:
            return { [key + '|regex']: value };
        case FilterMatchMode.NOT_CONTAINS:
            return { [key + '|regex']: `^(?!.*${value}).*$` };
        case FilterMatchMode.ENDS_WITH:
            return { [key + '|regex']: value + '$' };
        case FilterMatchMode.EQUALS:
            return { [key]: value };
        case FilterMatchMode.NOT_EQUALS:
            return { [key + '|ne']: value };
        case FilterMatchMode.LESS_THAN:
            return { [key + '|lt']: value };
        case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
            return { [key + '|lte']: value };
        case FilterMatchMode.GREATER_THAN:
            return { [key + '|gt']: value };
        case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
            return { [key + '|gte']: value };
        case FilterMatchMode.DATE_IS: {
            const tomorrow = new Date(value);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return {
                [key + '|range']: `${value},${tomorrow.toISOString()}`,
            };
        }
        case FilterMatchMode.DATE_IS_NOT: {
            const tomorrow = new Date(value);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return {
                [key + '|not,range']: `${value},${tomorrow.toISOString()}`,
            };
        }
        case FilterMatchMode.DATE_AFTER: {
            const tomorrow = new Date(value);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return { [key + '|gte']: tomorrow.toISOString() };
        }
        case FilterMatchMode.DATE_BEFORE: {
            return { [key + '|lt']: value };
        }
        default:
            throw new Error('match mode not implemented');
    }
};
