import storageJSONSafeParse from 'utils/storageJSONSafeParse';

export const MAX_CACHE_TIME = 30 * 60 * 1000; // 30 minutes

enum STORAGE_KEYS {
    USER_CONFIG = 'title-report:user-config',
    AUTH = 'title-report:auth',
    VIDEOID_LINK = 'title-report:videoid_link',
    IGNORED_LINKS = 'title-report:ignored_links',
    COUNT_FILTERS = 'title-report:count_filters',
}

namespace CacheSavedTypes {
    export type Auth = {
        token: string | null;
        user: any;
    };
    export type VideoIDLink = {
        data: Ether.TitlesReport.ILink;
        savedAt: number;
    }[];
}

abstract class BaseCache<T> {
    key: string;
    constructor(key: string) {
        this.key = key;
        this.validate();
    }

    abstract validate(): void;
    protected abstract getAndHandleStorage(...args: any[]): T | null;

    get(...args: any[]): T | null {
        this.validate();
        return this.getAndHandleStorage(...args);
    }
    abstract save(...args: any[]): void;
    delete(): void {
        localStorage.removeItem(this.key);
    }
}

namespace CacheManager {
    export class Auth extends BaseCache<CacheSavedTypes.Auth> {
        constructor() {
            super(STORAGE_KEYS.AUTH);
        }
        validate() {
            const auth = storageJSONSafeParse<CacheSavedTypes.Auth>(this.key);
            if (!auth) return;
            if (Object.prototype.toString.call(auth) !== '[object Object]') {
                this.delete();
                return;
            }
        }
        getAndHandleStorage() {
            return storageJSONSafeParse<CacheSavedTypes.Auth>(this.key);
        }
        save(value: CacheSavedTypes.Auth) {
            localStorage.setItem(this.key, JSON.stringify(value));
        }
    }

    export class UserConfig extends BaseCache<TitleReportApp.UserConfig> {
        defaultValue: TitleReportApp.UserConfig = {
            autoHideLink: true,
        };
        constructor() {
            super(STORAGE_KEYS.USER_CONFIG);
        }
        validate() {
            const config = storageJSONSafeParse<TitleReportApp.UserConfig>(
                this.key
            );
            if (!config) {
                localStorage.setItem(
                    this.key,
                    JSON.stringify(this.defaultValue)
                );
            } else {
                if (
                    Object.prototype.toString.call(config) !==
                        '[object Object]' ||
                    config.autoHideLink == null
                ) {
                    localStorage.setItem(
                        this.key,
                        JSON.stringify(this.defaultValue)
                    );
                    return;
                }
            }
        }
        getAndHandleStorage() {
            return storageJSONSafeParse<TitleReportApp.UserConfig>(this.key);
        }
        save(value: TitleReportApp.UserConfig) {
            localStorage.setItem(this.key, JSON.stringify(value));
        }
    }

    export class VideoIDLink extends BaseCache<Ether.TitlesReport.ILink[]> {
        constructor() {
            super(STORAGE_KEYS.VIDEOID_LINK);
        }

        cacheParse(): CacheSavedTypes.VideoIDLink | null {
            return storageJSONSafeParse<CacheSavedTypes.VideoIDLink>(
                this.key,
                function (_, value) {
                    var a;
                    if (typeof value === 'string') {
                        a =
                            /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z/.exec(
                                value
                            );
                        if (a) return new Date(value);
                    }
                    return value;
                }
            );
        }

        validate() {
            const links = this.cacheParse();
            if (!links) return;
            if (Object.prototype.toString.call(links) !== '[object Array]') {
                this.delete();
                return;
            }
            const filteredLinks = links.filter((cache) => {
                if (!cache.data || !cache.savedAt) return false;
                return !(
                    new Date().getTime() - cache.savedAt >
                    1000 * 60 * 60 * 2
                );
            });
            localStorage.setItem(this.key, JSON.stringify(filteredLinks));
        }
        getAndHandleStorage() {
            const links = this.cacheParse();
            if (!links) return null;
            return links.map((l) => l.data);
        }
        save(value: Ether.TitlesReport.ILink) {
            const links = this.cacheParse() ?? [];
            links.push({
                savedAt: new Date().getTime(),
                data: value,
            });
            localStorage.setItem(this.key, JSON.stringify(links));
        }
        deleteSpecific(id: string) {
            const links = this.cacheParse();
            if (!links) return;
            const newLinks = links?.filter((l) => l.data._id !== id);
            localStorage.setItem(this.key, JSON.stringify(newLinks));
        }
    }

    export class IgnoredLinks extends BaseCache<string[]> {
        constructor() {
            super(STORAGE_KEYS.IGNORED_LINKS);
        }
        validate() {
            const ignoredLinks = storageJSONSafeParse<string[]>(this.key);
            if (!ignoredLinks) {
                this.save([]);
                return;
            }
            if (
                Object.prototype.toString.call(ignoredLinks) !==
                '[object Array]'
            ) {
                this.delete();
                return;
            }
        }
        getAndHandleStorage() {
            return storageJSONSafeParse<string[]>(this.key);
        }
        save(value: string[]) {
            localStorage.setItem(this.key, JSON.stringify(value));
        }
    }

    export class CountFilters extends BaseCache<{ [key : string] : any }> {
        constructor() {
            super(STORAGE_KEYS.COUNT_FILTERS);
        }
        cacheParse(): TitleReportApp.CountFilters | null {
            return storageJSONSafeParse<TitleReportApp.CountFilters>(
                this.key,
                function (_, value) {
                    var a;
                    if (typeof value === 'string') {
                        a =
                            /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z/.exec(
                                value
                            );
                        if (a) return new Date(value);
                    }
                    return value;
                }
            );
        }
        validate() {
            const filters = this.cacheParse();
            if (!filters) return;
            if (
                Object.prototype.toString.call(filters) !==
                '[object Object]'
            ) {
                this.delete();
                return;
            }
        }
        getAndHandleStorage(key : 'link' | 'linkInbox') : { [key : string] : any } | null {
            const filters = this.cacheParse();
            if (!filters) return null;
            return filters[key];
        }
        save(key : 'link' | 'linkInbox', value: any) {
            let data = this.cacheParse() ?? {} as any;
            data[key] = value;
            localStorage.setItem(this.key, JSON.stringify(data));
        }
        get(key : 'link' | 'linkInbox') {
            this.validate();
            return this.getAndHandleStorage(key);
        }
    }
}

class CacheControl {
    static Auth = new CacheManager.Auth();
    static UserConfig = new CacheManager.UserConfig();
    static VideoIDLink = new CacheManager.VideoIDLink();
    static IgnoredLinks = new CacheManager.IgnoredLinks();
    static CountFilters = new CacheManager.CountFilters();
}

export default CacheControl;
