/*

    o-------------------------------------------------------------o
    | Title: The Batman (2022)        URL: https://... .com       |
    |                                                             |
    | Created at: [2022-03-09 18:28]  Type: Manual                |
    | Is Manual? [Yes]                Is Active? [Yes]            |
    | VideoID - Is Match? Maybe       VideoID Score: 0.67         |
    |                                                             |
    |   o-----------------------o    [Accept] [Reject]            |
    |   |                       |    [Maybe]  [Cancel]            |
    |   |      Screenshot       |                                 |
    |   |                       |                                 |
    |   o-----------------------o                                 |
    o-------------------------------------------------------------o
    
*/

import React, { useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import DateBadge from 'components/Misc/DateBadge';
import OidBadge from 'components/Misc/OidBadge';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { getOneTitleFromSlug } from 'services/titlesdb/title';
import './style.css';
import RowDataView from 'components/Misc/RowDataView';
import { Toast } from 'primereact/toast';
import clipboardy from 'clipboardy';

interface ILinkValidation {
    link: Ether.TitlesReport.ILink;
    index: number;
    onValidate(link_id: string, accepted: boolean | null, index: number): void;
    onIgnore(link_id: string): void;
    handleActions?(
        e: React.KeyboardEvent<HTMLDivElement>,
        linkId: string
    ): void;
    loading?: boolean;
    disabled?: boolean;
    isIgnored?: boolean;
    tabIndex?: number;
}

const cutLink = (url: string) => {
    if (url.length <= 95) return url;
    let newUrl = url.slice(0, 45);
    newUrl += ' ... ';
    newUrl += url.slice(-45);
    return newUrl;
};

const LinkValidation: React.FC<ILinkValidation> = ({
    link,
    index,
    loading,
    disabled,
    onValidate,
    onIgnore,
    handleActions,
    isIgnored,
    tabIndex,
}) => {
    const [dialogTitleVisible, setDialogTitleVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [isDetailSelected, setIsDetailSelected] = useState(false);

    const {
        data: titleData,
        isLoading: isTitleLoading,
        error: titleError,
    } = useQuery(
        ['get-one-title', link.titleinfo?.slug],
        () =>
            link.titleinfo?.slug
                ? getOneTitleFromSlug(link.titleinfo.slug)
                : null,
        {
            enabled: !!link.titleinfo?.slug,
            staleTime: 1000 * 60 * 15,
            cacheTime: 1000 * 60 * 30,
            retry: 2,
        }
    );

    const renderBool = (value?: boolean | null) => {
        return value == null ? (
            <Badge value='unknown' severity='warning' />
        ) : value ? (
            <Badge value='yes' severity='success' />
        ) : (
            <Badge value='no' severity='danger' />
        );
    };

    // const renderStatus = (value?: Ether.TitlesReport.OnOffStatus) => {
    //     return value === 'not_managed' ? (
    //         <Badge value='not managed' />
    //     ) : value === 'pending' ? (
    //         <Badge value='pending' severity='warning' />
    //     ) : value === 'sent' ? (
    //         <Badge value='sent' severity='info' />
    //     ) : value === 'done' ? (
    //         <Badge value='done' severity='success' />
    //     ) : (
    //         <Badge value='unknown' severity='danger' />
    //     );
    // };

    // const renderValidation = (value?: boolean | null) => {
    //     return value === undefined ? (
    //         <Badge value='not validated' />
    //     ) : value === null ? (
    //         <Badge value='unknown' severity='warning' />
    //     ) : value ? (
    //         <Badge value='accepted' severity='success' />
    //     ) : (
    //         <Badge value='rejected' severity='danger' />
    //     );
    // };

    const getFullTitle = (
        titleslug?: string | null,
        season?: string | null,
        episode?: string | null
    ) => {
        let title = titleslug;
        if (!titleError && titleData) {
            title = titleData?.name;
        }
        return (
            `${title}` +
            (season && season !== '' ? `, Season ${season}` : '') +
            (episode && episode !== '' ? `, Episode ${episode}` : '')
        );
    };

    const getTitleName = (titleslug?: string | null) => {
        let title = titleslug;
        if (!titleError && titleData) {
            title = titleData?.name;
        }
        return title;
    };

    const getTitleSeasonEpisode = (
        season?: string | null,
        episode?: string | null
    ) => {
        const values: string[] = [];
        if (season) values.push(`Season ${season}`);
        if (episode) values.push(`Episode ${episode}`);
        return values.join(', ');
    };

    const evaluateIsNoticePending = (
        notices?: Ether.TitlesReport.ILink['notices']
    ) => {
        if (!notices) return renderBool(undefined);
        const isPending =
            notices?.findIndex(
                (n) => n.status === 'new' || n.status === 'pending'
            ) > -1;
        return renderBool(isPending);
    };

    const evaluateLatestSentNotice = (
        notices?: Ether.TitlesReport.ILink['notices']
    ) => {
        if (!notices) return <Badge value='null' />;
        const sentNotices = notices
            .filter((n) => n.status === 'sent' || n.status === 'done')
            .sort((a, b) => {
                if (a.meta && b.meta) {
                    return a.meta.notice_sent_at > b.meta.notice_sent_at
                        ? -1
                        : 1;
                } else {
                    if (a.meta?.notice_sent_at) return -1;
                    if (b.meta?.notice_sent_at) return 1;
                }
                return 0;
            });

        if (sentNotices[0]?.meta?.notice_sent_at) {
            const id = sentNotices[0].meta.notice_id;
            const date = sentNotices[0].meta.notice_sent_at;
            return (
                <DateBadge
                    value={date}
                    tooltipText={`${id}, ${date.toISOString()}`}
                />
            );
        }

        return <Badge value='null' />;
    };

    const ImageDisplay: React.FC<{ src: string }> = ({ src }) => {
        const [imageStatus, setImageStatus] = useState<
            'loading' | 'error' | 'success'
        >('loading');

        return (
            <div className='img-container'>
                {imageStatus === 'loading' ? (
                    <div>Loading image...</div>
                ) : imageStatus === 'error' ? (
                    <div>Failed to load image</div>
                ) : null}
                <img
                    alt='Page screenshot'
                    src={src}
                    onLoad={() => setImageStatus('success')}
                    onError={() => setImageStatus('error')}
                    onClick={() => setSelectedImage(src)}
                />
            </div>
        );
    };

    const TitleInfoDialog = () => {
        const getAlternativeNames = () => {
            const alternativeNames: string[] = [];
            if (!titleData) return alternativeNames;
            for (const titleInfo of titleData.title) {
                const index = alternativeNames.findIndex(
                    (item) => item === titleInfo.value
                );
                if (index === -1 && titleInfo.value !== titleData.name)
                    alternativeNames.push(titleInfo.value);
            }
            return alternativeNames;
        };

        return (
            <Dialog
                visible={dialogTitleVisible}
                onHide={() => setDialogTitleVisible(false)}
                header={getFullTitle(
                    link.titleinfo?.slug,
                    link.titleinfo?.season,
                    link.titleinfo?.episode
                )}
                style={{ maxWidth: '60%' }}
            >
                {isTitleLoading
                    ? 'Loading title information...'
                    : titleError
                    ? 'Failed to load title data'
                    : titleData && (
                          <div
                              style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '8px',
                              }}
                          >
                              <div>
                                  <b>Name: </b>
                                  <span>{titleData.name}</span>
                              </div>
                              {getAlternativeNames().length > 0 && (
                                  <div>
                                      <b>AKA: </b>
                                      <ul>
                                          {getAlternativeNames().map((item) => (
                                              <li key={item}>{item}</li>
                                          ))}
                                      </ul>
                                  </div>
                              )}
                              <div>
                                  <b>Release date: </b>
                                  <span>
                                      {titleData.release_date
                                          ? titleData.release_date.toDateString()
                                          : 'unknown'}
                                  </span>
                              </div>
                              <div>
                                  <b>Type: </b>
                                  <span>{titleData.type}</span>
                              </div>
                              {titleData.imdb_id && (
                                  <div>
                                      <b>IMDB: </b>
                                      <a
                                          href={`https://www.imdb.com/title/${titleData.imdb_id}`}
                                          target='_blank'
                                          rel='noreferrer'
                                      >
                                          {`https://www.imdb.com/title/${titleData.imdb_id}`}
                                      </a>
                                  </div>
                              )}
                              {titleData.tmdb_id && (
                                  <div>
                                      <b>TMDB: </b>
                                      <a
                                          href={`https://www.themoviedb.org/${titleData.type}/${titleData.tmdb_id}`}
                                          target='_blank'
                                          rel='noreferrer'
                                      >
                                          {`https://www.themoviedb.org/${titleData.type}/${titleData.tmdb_id}`}
                                      </a>
                                  </div>
                              )}
                              {titleData.production_companies &&
                                  titleData.production_companies.length > 0 && (
                                      <div>
                                          <b>Production companies: </b>
                                          <span>
                                              {titleData.production_companies.join(
                                                  ', '
                                              )}
                                          </span>
                                      </div>
                                  )}
                              {titleData.synopsis &&
                                  titleData.synopsis.length > 0 && (
                                      <div>
                                          <b>Synopsis: </b>
                                          <ul>
                                              {titleData.synopsis
                                                  .filter(
                                                      (item) =>
                                                          item.value &&
                                                          item.value !== ''
                                                  )
                                                  .map((item) => (
                                                      <li
                                                          key={
                                                              item.language +
                                                              item.value
                                                          }
                                                          style={{
                                                              marginBottom:
                                                                  '8px',
                                                          }}
                                                      >
                                                          <b>
                                                              [{item.language}]
                                                          </b>{' '}
                                                          {item.value}
                                                      </li>
                                                  ))}
                                          </ul>
                                      </div>
                                  )}
                          </div>
                      )}
            </Dialog>
        );
    };

    const toastRef = useRef<Toast>(null);

    const UrlComponent: React.FC<{
        label: string;
        value: string | null;
        style?: React.CSSProperties;
    }> = ({ label, value, style }) => {
        return (
            <div
                style={{
                    wordWrap: 'break-word',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '8px',
                    ...style,
                }}
            >
                <b>{label}</b>
                {value && (
                    <Button
                        icon='pi pi-external-link'
                        className='p-button-sm p-button-outlined'
                        onClick={() => window.open(value)}
                    />
                )}
                <span
                    onClick={() =>
                        value
                            ? clipboardy.write(value).then(() =>
                                  toastRef?.current?.show({
                                      severity: 'success',
                                      summary: 'Copied',
                                      detail: 'Link copied to clipboard',
                                      life: 3000,
                                  })
                              )
                            : null
                    }
                >
                    {value && cutLink(value)}
                </span>
            </div>
        );
    };

    if (isIgnored) {
        return (
            <div
                id={`link_${index}`}
                className='link-validation-wrapper'
                tabIndex={tabIndex}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                }}
                onKeyDown={(e) => {
                    if (handleActions && ['a', 'r', 'm'].indexOf(e.key) === -1)
                        handleActions(e, link._id);
                }}
            >
                <span>This link has been ignored.</span>
                <Button
                    label='Cancel Ignore'
                    className='p-button-warning'
                    onClick={() => onIgnore(link._id)}
                />
            </div>
        );
    }

    return (
        <div
            id={`link_${index}`}
            className='link-validation-wrapper'
            tabIndex={tabIndex}
            onKeyDown={(e) => {
                if (handleActions) handleActions(e, link._id);
            }}
            style={{
                // backgroundImage:
                //     link.validation.status === 'done'
                //         ? link.validation.checks[
                //               link.validation.checks.length - 1
                //           ].accepted === false
                //             ? 'linear-gradient(to left, rgba(255,0,0,0) 60%, rgba(255, 0, 61, 0.35))'
                //             : link.validation.checks[
                //                   link.validation.checks.length - 1
                //               ].accepted === true
                //             ? 'linear-gradient(to left, rgba(255,0,0,0) 60%, rgba(57, 211, 106, 0.35))'
                //             : 'linear-gradient(to left, rgba(255,0,0,0) 60%, rgba(0, 178, 255, 0.35))'
                //         : undefined,
                backgroundImage:
                    link.validation.status === 'done'
                        ? link.validation.checks[
                              link.validation.checks.length - 1
                          ].accepted === false
                            ? 'linear-gradient(to left, rgba(255,0,0,0) 99.3%, var(--darkish-red) 99.3%)'
                            : link.validation.checks[
                                  link.validation.checks.length - 1
                              ].accepted === true
                            ? 'linear-gradient(to left, rgba(255,0,0,0) 99.3%, var(--semaphore-green) 99.3%)'
                            : 'linear-gradient(to left, rgba(255,0,0,0) 99.3%, var(--sky-blue) 99.3%)'
                        : undefined,
            }}
        >
            <Toast ref={toastRef} />
            <RowDataView
                modelData={link as any}
                header={link._id}
                visible={isDetailSelected}
                onHide={() => setIsDetailSelected(false)}
            />
            <TitleInfoDialog />
            <Dialog
                header='Full image'
                visible={!!selectedImage}
                onHide={() => setSelectedImage(null)}
                maximized={true}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt='Page screenshot'
                            style={{ maxHeight: '84vh' }}
                        />
                    )}
                </div>
            </Dialog>
            <div className='grid-group header'>
                <b
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        gridArea: 'title',
                    }}
                    onClick={() => setDialogTitleVisible(true)}
                >
                    {getTitleName(link.titleinfo?.slug)}
                </b>
                <span
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        gridArea: 'epses',
                    }}
                    onClick={() => setDialogTitleVisible(true)}
                >
                    {getTitleSeasonEpisode(
                        link.titleinfo?.season,
                        link.titleinfo?.episode
                    )}
                </span>
                <UrlComponent
                    label='URL '
                    value={link.value}
                    style={{ gridArea: 'url' }}
                />
                <UrlComponent
                    label='Referer '
                    value={link.referer}
                    style={{ gridArea: 'ref' }}
                />
                <div style={{ justifySelf: 'end', gridArea: 'button' }}>
                    <Button
                        icon='pi pi-search'
                        onClick={() => setIsDetailSelected(true)}
                        tooltip='View JSON dump'
                        tooltipOptions={{
                            position: 'left',
                        }}
                    />
                </div>
            </div>
            <div className='grid-group metadata'>
                <div>
                    <b>OID: </b>
                    <OidBadge value={link._id} />
                </div>
                <div>
                    <b>Captured at: </b>
                    <DateBadge value={link.captured_at} />
                </div>
                <div>
                    <b>Type: </b>
                    <Badge severity='info' value={link.source_type} />
                </div>
                <div>
                    <b>Source: </b>
                    <span>
                        <Badge value={link.source} />
                    </span>
                </div>
                {/* <div>
                    <b>Is Manual?: </b>
                    <span>{renderBool(link.is_manual)}</span>
                </div>
                <div>
                    <b>Validation: </b>
                    <span>
                        {renderValidation(
                            link.validation.checks.length > 0
                                ? link.validation.checks[
                                      link.validation.checks.length - 1
                                  ].accepted
                                : undefined
                        )}
                    </span>
                </div>
                <div>
                    <b>Validated at: </b>
                    {link.validation.checks.length > 0 ? (
                        <DateBadge
                            value={
                                link.validation.checks[
                                    link.validation.checks.length - 1
                                ].checked_at
                            }
                        />
                    ) : (
                        <Badge value='null' />
                    )}
                </div>
                <div></div>
                <div>
                    <b>OnOff Status?: </b>
                    <span>{renderStatus(link.onoff_data?.status)}</span>
                </div>
                <div>
                    <b>OnOff Active?: </b>
                    <span>{renderBool(link.onoff_data?.is_active)}</span>
                </div> */}
            </div>
            <div className='grid-group metadata'>
                <div>
                    <b>Notice pending?: </b>
                    <span>{evaluateIsNoticePending(link.notices)}</span>
                </div>
                <div>
                    <b>Last notice: </b>
                    <span>{evaluateLatestSentNotice(link.notices)}</span>
                </div>
            </div>
            <div className='grid-group footer'>
                {link.onoff_data?.last_is_active === true &&
                link.onoff_data.last_valid_id ? (
                    link.onoff_data.last_valid_captured_at &&
                    new Date().getTime() -
                        link.onoff_data.last_valid_captured_at.getTime() <=
                        1000 * 60 * 60 * 24 * 7 ? ( // 1 week
                        <ImageDisplay
                            src={`${window.API_URL}/get-onoff-screenshot/${link.onoff_data.last_valid_id}`}
                        />
                    ) : (
                        <div>Screenshot too old/invalid.</div>
                    )
                ) : (
                    <div>No screenshot</div>
                )}

                <div className='action-buttons'>
                    <Button
                        label='Accept'
                        className='p-button-success'
                        loading={loading}
                        disabled={disabled}
                        onClick={() => onValidate(link._id, true, index)}
                    />
                    <Button
                        label='Reject'
                        className='p-button-danger'
                        loading={loading}
                        disabled={disabled}
                        onClick={() => onValidate(link._id, false, index)}
                    />
                    <Button
                        label='Maybe'
                        className='p-button-info'
                        loading={loading}
                        disabled={disabled}
                        onClick={() => onValidate(link._id, null, index)}
                    />
                    <Button
                        label='Ignore'
                        className='p-button-warning'
                        loading={loading}
                        disabled={disabled}
                        onClick={() => onIgnore(link._id)}
                    />
                </div>
            </div>
        </div>
    );
};

export default LinkValidation;
