import React, { ReactElement } from 'react';
import {
    BrowserRouter,
    Routes as ReactRoutes,
    Route,
    Navigate,
    Outlet,
} from 'react-router-dom';
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
} from '@tanstack/react-query';

import Login from 'pages/Login';
import Main from 'pages/Main';

import Home from 'pages/Main/subpages/Home';
import Projects from 'pages/Main/subpages/Projects';
import Settings from 'pages/Main/subpages/Settings';
import Metrics from 'pages/Main/subpages/Metrics';

import ListProjects from 'pages/Main/subpages/Projects/subpages/ListProjects';
import ProjectSubpages from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages';

import CheckLinks from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/CheckLinks';
import ListReports from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ListReports';
import ListLinksInbox from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ListLinkInbox';
import ValidateVideoId from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ValidateVideoId';
import ListVideoMatches from 'pages/Main/subpages/Projects/subpages/ListProjects/subpages/ValidateVideoId/subpages/ListVideoMatches';

import LinkCount from 'pages/Main/subpages/Metrics/subpages/LinkCount';
import LinkInboxCount from 'pages/Main/subpages/Metrics/subpages/LinkInboxCount';

import InboxRules from 'pages/Main/subpages/InboxRules';
import CreateInboxRule from 'pages/Main/subpages/InboxRules/subpages/CreateInboxRule';
import UpdateInboxRule from 'pages/Main/subpages/InboxRules/subpages/UpdateInboxRule';

import { AuthProvider, useAuth } from 'hooks/useAuth';
import { getMyInfo } from 'services/title-report/me';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const Routes = () => {
    const ProtectedRoute: React.FC<{ children: ReactElement }> = ({
        children,
    }) => {
        const { status } = useAuth();
        if (status === 'loading')
            return (
                <div style={{ padding: '0px 16px' }}>
                    <h3>Validating login...</h3>
                </div>
            );
        if (status === 'error' || status === 'unauthenticated')
            return <Navigate to='/login' />;
        return children as ReactElement;
    };

    const ProtectedAdminRoute: React.FC<{ children: ReactElement }> = ({
        children,
    }) => {
        const myInfo = useQuery<Ether.IMe, Error>(
            ['me'],
            (): Promise<Ether.IMe> => getMyInfo()
        );

        if (myInfo.isFetching) return null;

        if (!myInfo.data?.is_superadmin) return <Navigate to='/' />;

        return children as ReactElement;
    };

    return (
        <ReactRoutes>
            <Route path='/login' element={<Login />} />
            <Route
                path='/'
                element={
                    <ProtectedRoute>
                        <Main />
                    </ProtectedRoute>
                }
            >
                <Route index element={<Home />} />
                <Route path='projects' element={<Projects />}>
                    <Route index element={<ListProjects />} />
                    <Route path=':projectOid' element={<ProjectSubpages />}>
                        <Route path='check-links' element={<CheckLinks />} />
                        <Route path='reports' element={<ListReports />} />
                        <Route path='videoid-validation'>
                            <Route index element={<ValidateVideoId />} />
                            <Route
                                path='matches'
                                element={<ListVideoMatches />}
                            />
                        </Route>
                        <Route path='inbox' element={<ListLinksInbox />} />
                    </Route>
                </Route>
                <Route path='settings' element={<Settings />} />
                <Route
                    path='metrics'
                    element={
                        <ProtectedAdminRoute>
                            <Outlet />
                        </ProtectedAdminRoute>
                    }
                >
                    <Route index element={<Metrics />} />
                    <Route path='link-count' element={<LinkCount />} />
                    <Route path='inbox-count' element={<LinkInboxCount />} />
                </Route>
                <Route
                    path='inbox-rules'
                    element={
                        <ProtectedAdminRoute>
                            <Outlet />
                        </ProtectedAdminRoute>
                    }
                >
                    <Route index element={<InboxRules />} />
                    <Route path='create' element={<CreateInboxRule />} />
                    <Route path='update/:name' element={<UpdateInboxRule />} />
                </Route>
                <Route path='*' element={<h1>Not found</h1>} />
            </Route>
        </ReactRoutes>
    );
};

const Routing = () => {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <Routes />
                </AuthProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
};

export default Routing;
