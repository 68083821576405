import api from 'services/api';

const apiUrl = window.EXTERNAL_URI_VIDEOID;

export const getOneVideo = (
    id: string,
    options?: {
        signal?: AbortSignal;
    }
): Promise<Ether.VideoID.IVideo | null> => {
    return new Promise<Ether.VideoID.IVideo | null>((resolve, reject) => {
        api.get<Ether.IApi<any>>(`${apiUrl}/list-videos`, {
            signal: options?.signal,
            params: {
                _id: id,
            },
        })
            .then((result) => {
                const count = result.data.meta.payload_count;
                if (count === 1) resolve(result.data.payload[0]);
                else resolve(null);
            })
            .catch(reject);
    });
};
