import {
    DataTableFilterMeta,
    DataTableOperatorFilterMetaData,
    DataTableSortMeta,
} from 'primereact/datatable';
import api from 'services/api';
import { handleFilterMatchMode } from 'services/utils';

const apiUrl = window.API_URL;

const handleDataTableFilterMeta = (filters?: DataTableFilterMeta) => {
    if (!filters) return {};
    let paramFilters: { [key: string]: string | number } = {};
    if (filters['value']) {
        const filterMeta = filters['value'] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'value',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    if (filters['domain']) {
        const filterMeta = filters['domain'] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'domain',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    if (filters['source']) {
        const filterMeta = filters['source'] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'source',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    if (filters['captured_at']) {
        const filterMeta = filters[
            'captured_at'
        ] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'captured_at',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    return paramFilters;
};

export const listLinkInboxByProject = (
    projectId: string,
    options?: {
        signal?: AbortSignal;
        limit?: number;
        offset?: number;
        filters?: DataTableFilterMeta;
        sortField?: string | null;
        sortOrder?: DataTableSortMeta['order'] | null;
    }
): Promise<Ether.TitlesReport.ILinkInbox[]> => {
    return new Promise<Ether.TitlesReport.ILinkInbox[]>((resolve, reject) => {
        const filters = handleDataTableFilterMeta(options?.filters);

        let order: string | null = null;
        if (options?.sortField && options.sortOrder) {
            if (options.sortOrder === 1) {
                order = options.sortField;
            } else if (options.sortOrder === -1) {
                order = '-' + options.sortField;
            }
        }
        if (order) filters['order'] = order;

        api.get<Ether.IApi<any>>(`${apiUrl}/list-link-inbox`, {
            signal: options?.signal,
            params: {
                'projects@oid': projectId,
                limit: options?.limit,
                offset: options?.offset,
                ...filters,
            },
        })
            .then((result) => {
                const links = result.data.payload;
                resolve(links);
            })
            .catch(reject);
    });
};

export const countLinkInbox = <T>(options?: {
    signal?: AbortSignal;
    filters?: { [key: string]: any };
}) => {
    return new Promise<(Partial<T> & { count: number })[]>(
        (resolve, reject) => {
            api.get<{
                success: string;
                meta: { payload_count: number; [key: string]: unknown };
                payload: (Partial<T> & { count: number })[] | number;
            }>(`${apiUrl}/count-link-inbox`, {
                signal: options?.signal,
                params: {
                    ...options?.filters,
                },
                timeout : 15 * 60 * 1000,
            })
                .then((result) => {
                    const data = result.data.payload;
                    if (typeof data === 'number') {
                        resolve([
                            {
                                count: data,
                            } as any,
                        ]);
                    } else {
                        resolve(data);
                    }
                })
                .catch(reject);
        }
    );
};