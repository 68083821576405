import CustomInputText from 'components/Form/CustomInputText';
import { useToast } from 'hooks/useToast';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import { createInboxRule } from 'services/title-report/inbox-rules';

const CreateInboxRule = () => {
    const toast = useToast();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        domain: '',
        regex: '',
    });

    const createRuleMutation = useMutation<
        Ether.TitlesReport.IInboxRules['rules'][0],
        Error,
        Ether.TitlesReport.IInboxRules['rules'][0]
    >(async (data) => createInboxRule(data), {
        onSuccess: (_, name) => {
            toast?.show({
                severity: 'success',
                summary: `Create rule ${name}`,
            });
            navigate('..');
        },
        onError: (err) => {
            toast?.show({
                severity: 'error',
                summary: 'Error creating',
                detail: err.message,
                life: 10000,
            });
        },
    });

    const handleSubmit = () => {
        createRuleMutation.mutate(formData);
    };

    return (
        <div>
            <Link to='..'>
                <Button label='Go back' />
            </Link>
            <h2>Create Inbox Rule</h2>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                }}
            >
                <CustomInputText
                    label='Name'
                    name='name'
                    value={formData.name}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            name: e.target.value,
                        }))
                    }
                />
                <CustomInputText
                    label='Domain'
                    name='domain'
                    value={formData.domain}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            domain: e.target.value,
                        }))
                    }
                />
                <CustomInputText
                    label='Regex'
                    name='regex'
                    value={formData.regex}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            regex: e.target.value,
                        }))
                    }
                />
                <Button
                    label='Create'
                    icon='pi pi-plus'
                    onClick={handleSubmit}
                    disabled={
                        formData.name === '' ||
                        formData.domain === '' ||
                        formData.regex === ''
                    }
                />
            </div>
        </div>
    );
};

export default CreateInboxRule;
