import api from 'services/api';
const apiUrl = window.API_URL;

export const getProjectTitles = (
    project_id: string,
    options?: {
        signal?: AbortSignal;
    }
): Promise<Ether.TitlesReport.ITitleSetup[]> => {
    return new Promise<Ether.TitlesReport.ITitleSetup[]>((resolve, reject) => {
        api.get<Ether.IApi<Ether.TitlesReport.ITitleSetup>>(
            `${apiUrl}/get-project-titles/${project_id}`,
            {
                signal: options?.signal,
            }
        )
            .then((result) => {
                const titles = result.data.payload;
                resolve(titles);
            })
            .catch(reject);
    });
};
