import { useToast } from 'hooks/useToast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import {
    deleteInboxRule,
    listInboxRules,
} from 'services/title-report/inbox-rules';

interface IModalDeleteRule {
    selectedRuleName?: string | null;
    onDelete: (name?: string | null) => void;
    onHide: () => void;
}

const ModalDeleteRule: React.FC<IModalDeleteRule> = ({
    onHide,
    onDelete,
    selectedRuleName,
}) => {
    return (
        <Dialog
            header={`Delete rule ${selectedRuleName ?? ''}`}
            onHide={onHide}
            visible={!!selectedRuleName}
        >
            <p>Are you sure you want to delete rule {selectedRuleName}?</p>
            <div style={{ display: 'flex', gap: '8px' }}>
                <Button
                    label='Yes'
                    className='p-button-success'
                    onClick={() => onDelete(selectedRuleName)}
                />
                <Button
                    label='No'
                    className='p-button-danger'
                    onClick={onHide}
                />
            </div>
        </Dialog>
    );
};

const InboxRules = () => {
    const toast = useToast();

    const [selectedRule, setSelectedRule] = useState<
        Ether.TitlesReport.IInboxRules['rules'][0] | null
    >(null);

    const inboxRulesQuery = useQuery<Ether.TitlesReport.IInboxRules, Error>(
        ['list-inbox-rules'],
        () => listInboxRules(),
        {
            refetchOnMount: true,
        }
    );

    const deleteRuleMutation = useMutation<
        boolean,
        Error,
        string | null | undefined
    >(
        async (name) => {
            if (!name) return true;
            return deleteInboxRule(name);
        },
        {
            onSuccess: (_, name) => {
                setSelectedRule(null);
                inboxRulesQuery.refetch();
                toast?.show({
                    severity: 'success',
                    summary: `Deleted rule ${name}`,
                });
            },
            onError: (err) => {
                toast?.show({
                    severity: 'error',
                    summary: 'Error deleting',
                    detail: err.message,
                    life: 10000,
                });
            },
        }
    );

    const renderActionButtons = (
        rowData: Ether.TitlesReport.IInboxRules['rules'][0]
    ) => {
        return (
            <div style={{ display: 'flex', gap: '8px' }}>
                <Link to={`update/${rowData.name}`}>
                    <Button icon='pi pi-pencil' className='p-button-outlined' />
                </Link>
                <Button
                    icon='pi pi-times'
                    className='p-button-danger'
                    onClick={() => setSelectedRule(rowData)}
                />
            </div>
        );
    };

    return (
        <div>
            <ModalDeleteRule
                selectedRuleName={selectedRule?.name as string}
                onHide={() => setSelectedRule(null)}
                onDelete={(name) => deleteRuleMutation.mutate(name)}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2>Inbox Rules</h2>
                {inboxRulesQuery.data?.version && (
                    <h2>Version {inboxRulesQuery.data.version}</h2>
                )}
            </div>
            <div style={{ display: 'flex' }}>
                <Link to='create'>
                    <Button label='Add new rule' icon='pi pi-plus' />
                </Link>
            </div>
            <DataTable
                loading={inboxRulesQuery.isLoading}
                value={inboxRulesQuery.data?.rules}
            >
                <Column header='Name' field='name' />
                <Column header='Domain' field='domain' />
                <Column
                    header='Regex'
                    field='regex'
                    body={(data) => <pre>{data.regex}</pre>}
                />
                <Column header='Actions' body={renderActionButtons} />
            </DataTable>
        </div>
    );
};

export default InboxRules;
