import { Toast } from 'primereact/toast';
import { createContext, useContext, useRef } from 'react';

const ToastContext = createContext<Toast | null>(null);

const ToastProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const toastRef = useRef<Toast>(null);

    return (
        <>
            <Toast ref={toastRef} onRemove={() => toastRef.current?.clear()} />
            <ToastContext.Provider
                value={toastRef.current}
            >
                {children}
            </ToastContext.Provider>
        </>
    );
};

const useToast = () => {
    const context = useContext(ToastContext);
    return context;
};

export { ToastProvider, useToast };
