import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import OidBadge from 'components/Misc/OidBadge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { listProjects } from 'services/title-report/project';

import './style.css';
import { listAllGroups } from 'services/title-report/group';
import { getMyInfo } from 'services/title-report/me';

const ListProjects = () => {
    const navigate = useNavigate();

    const { data, error, status } = useQuery<
        Ether.TitlesReport.IProject[],
        Error
    >(['list-project'], async (): Promise<Ether.TitlesReport.IProject[]> => {
        return listProjects();
    });

    const myInfo = useQuery<Ether.IMe, Error>(
        ['me'],
        (): Promise<Ether.IMe> => getMyInfo()
    );

    const groupsQuery = useQuery<{ [key: string]: string }, Error>(
        ['list-groups'],
        async () => {
            const groups = await listAllGroups();
            const map: { [key: string]: string } = {};
            groups.forEach((g) => {
                map[g._id] = g.name;
            });
            return map;
        },
        {
            enabled: !!myInfo.data?.is_superadmin,
        }
    );

    const renderActionButtons = (rowData: Ether.TitlesReport.IProject) => {
        return (
            <div className='action-buttons-wrap'>
                <Button
                    className='p-button-outlined'
                    label='Links check'
                    onClick={() => navigate(`${rowData._id}/check-links`)}
                />
                <Button
                    className='p-button-outlined'
                    label='Reports'
                    onClick={() => navigate(`${rowData._id}/reports`)}
                />
                <Button
                    className='p-button-outlined'
                    label='Link inbox'
                    onClick={() => navigate(`${rowData._id}/inbox`)}
                />
                {rowData.videoid_enabled && (
                    <Button
                        className='p-button-outlined'
                        label='VideoID Validation'
                        onClick={() =>
                            navigate(`${rowData._id}/videoid-validation`)
                        }
                    />
                )}
            </div>
        );
    };

    return (
        <div>
            <h2>Projects</h2>
            {error ? (
                error.message
            ) : (
                <DataTable
                    value={data}
                    loading={status === 'loading' || groupsQuery.isInitialLoading}
                    emptyMessage='No projects available'
                >
                    <Column
                        field='_id'
                        header='OID'
                        body={(rowData) => <OidBadge value={rowData._id} />}
                    />
                    {groupsQuery.isSuccess && (
                        <Column
                            field='group_id'
                            header='Group'
                            body={(rowData) =>
                                groupsQuery.data[rowData.group_id]
                            }
                        />
                    )}
                    <Column field='name' header='Name' />
                    <Column header='Actions' body={renderActionButtons} />
                </DataTable>
            )}
        </div>
    );
};

export default ListProjects;
