import api from 'services/api';

const apiUrl = window.API_URL;

export const isTokenValid = (
    token: string,
    options?: {
        signal?: AbortSignal;
    }
): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        api.get(`${apiUrl}/me`, {
            signal: options?.signal,
            headers: {
                'access-token': token,
            },
        })
            .then(() => {
                resolve(true);
            })
            .catch((err) => {
                if (
                    err.status === 400 &&
                    err.data?.data === 'authentication error'
                )
                    resolve(false);
                reject(err);
            });
    });
};

export const getMyInfo = (options?: {
    signal?: AbortSignal;
}): Promise<Ether.IMe> => {
    return new Promise<Ether.IMe>((resolve, reject) => {
        api.get<{ success: boolean; user: Ether.IMe }>(`${apiUrl}/me`, {
            signal: options?.signal,
        })
            .then((result) => {
                resolve(result.data.user);
            })
            .catch(reject);
    });
};
