import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import OidBadge from '../OidBadge';
import CustomInputText from 'components/Form/CustomInputText';
import isValidOid from 'utils/isValidOid';

const OidFilter = (options : ColumnFilterElementTemplateOptions) => {
    const isError = options.value && !isValidOid(options.value);

    return (
        <>
            <CustomInputText
                style={{
                    minWidth: '160px',
                }}
                placeholder='Search by ID'
                value={options.value === null ? '' : options.value}
                onChange={(e) => options.filterCallback(e.target.value)}
                maxLength={24}
                error={isError && 'Invalid OID'}
            />
        </>
    );
};

const OidColumn = () => {
    return (
        <Column
            field='_id'
            header='OID'
            filter
            filterElement={OidFilter}
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
            body={(rowData) => <OidBadge value={rowData._id} />}
            style={{minWidth: '132px'}}
        />
    );
};

export default OidColumn;
