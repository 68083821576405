import api from 'services/api';

const apiUrl = window.API_URL;

export const listAllGroups = (options?: {
    signal?: AbortSignal;
}): Promise<Ether.TitlesReport.IGroup[]> => {
    return new Promise<Ether.TitlesReport.IGroup[]>((resolve, reject) => {
        api.get<Ether.IApi<any>>(`${apiUrl}/list-groups`, {
            signal: options?.signal,
            params: {
                limit: 1000,
            },
        })
            .then((result) => {
                const groups = result.data.payload;
                resolve(groups);
            })
            .catch(reject);
    });
};