import CustomDropdown from 'components/Form/CustomDropdown';
import CustomInputText from 'components/Form/CustomInputText';
import NoTotalPaginator from 'components/Form/NoTotalPaginator';
import LoadingSpinner from 'components/Misc/LoadingSpinner';
import CacheControl from 'controller/cache/cacheController';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getLinksFromProject, validateLink } from 'services/title-report/link';
import TitlesFilter from '../components/TitlesFilter';
import LinkValidation from './components/LinkValidation';

import './style.css';
import { useProjectQuery } from '..';
import { DataTableFilterMetaData } from 'primereact/datatable';

// interface ILinkFilters {
//     onoff_active: IFilterOnOffActive;
//     onoff_status: IFilterOnOffStatus;
//     source: string;
//     source_type: string;
//     validation: IFilterValidation;
//     url: string;
//     titledb_slug: string;
//     referer: string;
//     order: string | null;
// }

// interface IAppliedLinkFilters extends ILinkFilters {
//     isApplied: boolean;
// }

const CheckLinks = () => {
    const toastRef = useRef<Toast>(null);
    const selectedIndex = useRef<number | null>(null);
    const params = useParams();

    const projectQuery = useProjectQuery();

    const [config] = useState<TitleReportApp.UserConfig>(() => {
        const value = CacheControl.UserConfig.get();
        if (value) {
            return value;
        }
        return {
            autoHideLink: true,
        };
    });

    const [ignoredLinks, setIgnoredLinks] = useState<string[]>(() => {
        return CacheControl.IgnoredLinks.get() ?? [];
    });

    const [pageData, setPageData] = useState({
        page: 1,
        rows: 10,
    });

    const [filters, setFilters] = useState<{
        [key: string]: DataTableFilterMetaData;
    }>({
        value: {
            value: '',
            matchMode: 'contains',
        },
        source: {
            value: '',
            matchMode: 'equals',
        },
        source_type: {
            value: '',
            matchMode: 'contains',
        },
        validation: {
            value: 'not-validated',
            matchMode: 'equals',
        },
        onoff_active: {
            value: 'any',
            matchMode: 'equals',
        },
        onoff_status: {
            value: 'any',
            matchMode: 'equals',
        },
        'titleinfo.slug': {
            value: '',
            matchMode: 'equals',
        },
        referer: {
            value: '',
            matchMode: 'contains',
        },
        // gambiarra
        order: {
            value: undefined,
            matchMode: 'equals',
        },
    });
    const [appliedFilters, setAppliedFilters] = useState<typeof filters | null>(
        null
    );

    const [finalData, setFinalData] = useState<Ether.TitlesReport.ILink[]>([]);

    const linkQuery = useQuery<Ether.TitlesReport.ILink[], Error>(
        [
            'get-links-from-project',
            params.projectOid,
            appliedFilters,
            pageData.page,
            pageData.rows,
        ],
        async (): Promise<Ether.TitlesReport.ILink[]> => {
            if (!appliedFilters) throw new Error('unreachable');
            if (!params.projectOid) throw new Error('no project oid supplied');
            return getLinksFromProject(params.projectOid, {
                sortField: appliedFilters.order.value
                    ? appliedFilters.order.value.replace('-', '')
                    : 'created_at',
                sortOrder: appliedFilters.order.value?.startsWith('-') ? -1 : 1,
                limit: pageData.rows,
                offset: (pageData.page - 1) * pageData.rows,
                filters: appliedFilters,
            });
        },
        {
            onSuccess: (data) => {
                if (data) {
                    let timeout = 0;
                    const element = document.querySelector(
                        `#link_${selectedIndex.current}`
                    );
                    if (
                        appliedFilters?.validation.value === 'not-validated' &&
                        element
                    ) {
                        element.className = 'link-validation-wrapper close';
                        timeout = 200;
                        selectedIndex.current = null;
                    }
                    setTimeout(() => setFinalData(data), timeout);
                }
            },
            enabled:
                config.autoHideLink &&
                projectQuery?.isSuccess &&
                !!appliedFilters,
        }
    );

    const applyFiltersHandler = () => {
        setAppliedFilters({
            ...filters,
        });
    };

    const linkMutation = useMutation<
        boolean,
        Error,
        { link_id: string; accepted: boolean | null }
    >(
        (params) => {
            return validateLink(params.link_id, params.accepted);
        },
        {
            onSuccess: (_, params) => {
                if (config.autoHideLink) linkQuery.refetch();
                else if (linkQuery.data) {
                    const index = linkQuery.data.findIndex(
                        (link) => link._id === params.link_id
                    );
                    if (index > -1) {
                        linkQuery.data[index].validation.status = 'done';
                        linkQuery.data[index].validation.checks.push({
                            accepted: params.accepted,
                            checked_at: new Date(),
                            description: '',
                        });
                    }
                }
            },
            onError: (err) =>
                toastRef.current?.show({
                    severity: 'error',
                    summary: 'Failed to validate',
                    detail: err.message,
                }),
        }
    );

    const handleValidate = (
        link_id: string,
        accepted: boolean | null,
        index: number
    ) => {
        selectedIndex.current = index;
        if (!linkQuery.isSuccess || linkMutation.isLoading) return;
        linkMutation.mutateAsync({
            link_id,
            accepted,
        });
    };

    const handleIgnore = (link_id: string) => {
        const index = ignoredLinks.findIndex((item) => item === link_id);
        if (index !== -1) {
            setIgnoredLinks((old) => {
                const newArray = [...old];
                newArray.splice(index, 1);
                return newArray;
            });
        } else {
            setIgnoredLinks((old) => {
                const newArray = [...old];
                newArray.push(link_id);
                return newArray;
            });
        }
    };

    useEffect(() => {
        if (selectedIndex.current) {
            if (
                finalData.length < pageData.rows &&
                finalData.length === selectedIndex.current
            )
                selectedIndex.current -= 1;
            document
                .querySelector<HTMLDivElement>(`#link_${selectedIndex.current}`)
                ?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalData]);

    useEffect(() => {
        CacheControl.IgnoredLinks.save(ignoredLinks);
    }, [ignoredLinks]);

    useEffect(() => {
        selectedIndex.current = null;
        setPageData((old) => ({
            ...old,
            page: 1,
        }));
    }, [pageData.rows, appliedFilters]);

    useEffect(() => {
        if (!config.autoHideLink) {
            selectedIndex.current = null;
            if (!!appliedFilters) linkQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageData, appliedFilters]);

    const handleLinkActions = (
        e: React.KeyboardEvent<HTMLDivElement>,
        linkId: string
    ) => {
        const index = parseInt(e.currentTarget.id.split('link_')[1]);
        let element: HTMLDivElement | null = null;
        if (['ArrowUp', 'ArrowLeft', 'p'].indexOf(e.key) !== -1) {
            element = document.querySelector(`#link_${index - 1}`);
        } else if (['ArrowDown', 'ArrowRight', 'n'].indexOf(e.key) !== -1) {
            element = document.querySelector(`#link_${index + 1}`);
        } else if (['a', 'r', 'm'].indexOf(e.key) !== -1) {
            let accept: boolean | null = null;
            if (e.key === 'a') accept = true;
            else if (e.key === 'r') accept = false;
            handleValidate(linkId, accept, index);
        } else if (e.key === 'i') {
            handleIgnore(linkId);
        }
        if (element) element.focus();
    };

    return (
        <div>
            <Toast ref={toastRef} />
            {projectQuery?.data && (
                <>
                    <h2>{projectQuery?.data.name} - Check Links</h2>
                    <h3>Filters</h3>
                    <div className='filter-group-links'>
                        <TitlesFilter
                            projectId={params.projectOid}
                            value={filters['titleinfo.slug'].value}
                            onChange={(value) =>
                                setFilters((old) => ({
                                    ...old,
                                    'titleinfo.slug': {
                                        ...old['titleinfo.slug'],
                                        value,
                                    },
                                }))
                            }
                        />
                        <CustomInputText
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    applyFiltersHandler();
                                }
                            }}
                            label='Source'
                            onChange={(e) =>
                                setFilters((old) => ({
                                    ...old,
                                    source: {
                                        ...old.source,
                                        value: e.target.value,
                                    },
                                }))
                            }
                            value={filters.source.value}
                            placeholder='Ex. netu'
                        />
                        <CustomInputText
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    applyFiltersHandler();
                                }
                            }}
                            label='Source type'
                            onChange={(e) =>
                                setFilters((old) => ({
                                    ...old,
                                    source_type: {
                                        ...old.source_type,
                                        value: e.target.value,
                                    },
                                }))
                            }
                            value={filters.source_type.value}
                            placeholder='Ex. cyberlocker'
                        />
                        <CustomDropdown
                            label='Validation'
                            value={filters.validation.value}
                            onChange={(e) =>
                                setFilters((old) => ({
                                    ...old,
                                    validation: {
                                        ...old.validation,
                                        value: e.value,
                                    },
                                }))
                            }
                            options={[
                                {
                                    value: 'validated',
                                    label: 'Validated',
                                },
                                {
                                    value: 'not-validated',
                                    label: 'Not validated',
                                },
                                {
                                    value: 'any',
                                    label: 'Any',
                                },
                            ]}
                        />
                        <CustomInputText
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') applyFiltersHandler();
                            }}
                            label='URL'
                            value={filters.value.value}
                            placeholder='Ex. waaw.tv'
                            onChange={(e) =>
                                setFilters((old) => ({
                                    ...old,
                                    value: {
                                        ...old.value,
                                        value: e.target.value,
                                    },
                                }))
                            }
                        />
                        <CustomInputText
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') applyFiltersHandler();
                            }}
                            label='Referer'
                            onChange={(e) =>
                                setFilters((old) => ({
                                    ...old,
                                    referer: {
                                        ...old.referer,
                                        value: e.target.value,
                                    },
                                }))
                            }
                            value={filters.referer.value}
                            placeholder='Ex. pelisplus.sh'
                        />
                        <CustomDropdown
                            label='OnOff Active'
                            value={filters.onoff_active.value}
                            onChange={(e) =>
                                setFilters((old) => ({
                                    ...old,
                                    onoff_active: {
                                        ...old.onoff_active,
                                        value: e.value,
                                    },
                                }))
                            }
                            options={[
                                {
                                    value: 'on',
                                    label: 'On',
                                },
                                {
                                    value: 'off',
                                    label: 'Off',
                                },
                                {
                                    value: 'unknown',
                                    label: 'Unknown',
                                },
                                {
                                    value: 'any',
                                    label: 'Any',
                                },
                            ]}
                        />
                        <CustomDropdown
                            label='OnOff Status'
                            value={filters.onoff_status.value}
                            onChange={(e) =>
                                setFilters((old) => ({
                                    ...old,
                                    onoff_status: {
                                        ...old.onoff_status,
                                        value: e.value,
                                    },
                                }))
                            }
                            options={[
                                {
                                    value: 'not_managed',
                                    label: 'Not managed',
                                },
                                {
                                    value: 'pending',
                                    label: 'Pending',
                                },
                                {
                                    value: 'done',
                                    label: 'Done',
                                },
                                {
                                    value: 'sent',
                                    label: 'Sent',
                                },
                                {
                                    value: 'any',
                                    label: 'Any',
                                },
                            ]}
                        />
                        <CustomDropdown
                            label='Sort by'
                            value={filters.order.value}
                            onChange={(e) =>
                                setFilters((old) => ({
                                    ...old,
                                    order: {
                                        ...old.order,
                                        value: e.value,
                                    },
                                }))
                            }
                            placeholder='Sort by'
                            showClear
                            options={[
                                {
                                    value: '_id',
                                    label: 'ID asc',
                                },
                                {
                                    value: '-_id',
                                    label: 'ID desc',
                                },
                                {
                                    value: 'captured_at',
                                    label: 'Captured at asc',
                                },
                                {
                                    value: '-captured_at',
                                    label: 'Captured at desc',
                                },
                            ]}
                        />
                        <Button
                            label='Apply filters'
                            onClick={applyFiltersHandler}
                            style={{ alignSelf: 'end' }}
                        />
                    </div>

                    {linkQuery.isInitialLoading ? (
                        <div>
                            <LoadingSpinner /> Loading...
                        </div>
                    ) : linkQuery.isError ? (
                        <p>{linkQuery.error.message}</p>
                    ) : (
                        linkQuery.isSuccess &&
                        linkQuery.data && (
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '8px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <NoTotalPaginator
                                        page={pageData.page}
                                        rows={pageData.rows}
                                        onPageChange={setPageData}
                                        disableNext={
                                            !linkQuery.data ||
                                            linkQuery.data.length <
                                                pageData.rows
                                        }
                                    />
                                    <Button
                                        label='Refresh'
                                        icon='pi pi-refresh'
                                        onClick={() => {
                                            linkQuery.remove();
                                            linkQuery.refetch();
                                        }}
                                        style={{ marginBottom: '8px' }}
                                    />
                                </div>
                                {linkQuery.data.length === 0 ? (
                                    <h3>No links found</h3>
                                ) : (
                                    finalData.map((link, index) => (
                                        <LinkValidation
                                            key={link._id}
                                            link={link}
                                            index={index}
                                            loading={
                                                linkMutation.isLoading &&
                                                index === selectedIndex.current
                                            }
                                            disabled={linkMutation.isLoading}
                                            onValidate={handleValidate}
                                            onIgnore={handleIgnore}
                                            isIgnored={
                                                ignoredLinks.findIndex(
                                                    (item) => item === link._id
                                                ) !== -1
                                            }
                                            tabIndex={10}
                                            handleActions={handleLinkActions}
                                        />
                                    ))
                                )}
                            </div>
                        )
                    )}
                </>
            )}
        </div>
    );
};

export default CheckLinks;
