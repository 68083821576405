import Logo, { RedirectableLogo } from 'components/Misc/Logo';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './style.css';

interface INavbar {
    useNoRedirectLogo?: boolean;
    navigations?: {
        label: string;
        path: string;
    }[];
    endContent?: ReactElement;
}

const Navbar: React.FC<INavbar> = ({
    navigations,
    useNoRedirectLogo,
    endContent,
}) => {
    const location = useLocation();

    const Navigations = () => {
        if (navigations) {
            return (
                <div className='navigations'>
                    {navigations.map((nav) => {
                        return (
                            <div key={nav.path}>
                                <Link
                                    aria-label={nav.label}
                                    to={nav.path}
                                    className={
                                        location.pathname === nav.path
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    {nav.label}
                                </Link>
                            </div>
                        );
                    })}
                </div>
            );
        }
        return <></>;
    };

    return (
        <section className='navbar'>
            <div className='navbar-content'>
                {useNoRedirectLogo ? <Logo /> : <RedirectableLogo />}
                <Navigations />
            </div>
            <div className='navbar-end'>{endContent}</div>
        </section>
    );
};

export default Navbar;
