import { stringify } from 'csv-stringify/browser/esm/sync';
import downloadFromUrl from './downloadFromUrl';

interface GenericEtherItem {
    _id: string;
    [key: string]: any;
}

export async function exportAsCsv(options: {
    filename: string;
    filters: { [key: string]: string | number };
    csvHeaders: (
        | string
        | {
              field: string;
              name?: string;
              parseFunction?(value: any): string;
          }
    )[];
    fetchFn(filters: {
        [key: string]: string | number;
    }): Promise<GenericEtherItem[]>;
    crossFetchItem?: (item: GenericEtherItem) => Promise<GenericEtherItem>;
}) {
    const LIMIT = 500;

    const csvData: any[] = [];

    const { filename, filters, fetchFn, csvHeaders, crossFetchItem } = options;
    filters['order'] = '_id';
    filters['limit'] = LIMIT;

    let continueRequests = true;
    let requestsMade = 0;

    const finalCsvHeaders: string[] = csvHeaders.map((header) => {
        if (typeof header !== 'string') return header.name ?? header.field;
        return header;
    });
    while (continueRequests) {
        requestsMade += 1;
        if (requestsMade > 3000) throw new Error('exceeded amount of requests');

        const fetchItems = async () => {
            const items = await fetchFn(filters);
            if (!crossFetchItem) return items;
            const promises = items.map((i) => crossFetchItem(i));
            return await Promise.all(promises);
        };

        const items = await fetchItems();

        if (items.length > 0) {
            if (items.length < LIMIT) continueRequests = false;

            items.forEach((item) => {
                const itemData: any[] = [];
                csvHeaders.forEach((field) => {
                    if (typeof field === 'string') {
                        let value: any = item;
                        const splittedFields = field.split('.');
                        splittedFields.every((subField) => {
                            if (value[subField] instanceof Date) {
                                value = value[subField].toISOString();
                            } else value = value[subField];
                            if (value == null) return false;
                            return true;
                        });
                        itemData.push(value);
                    } else {
                        let value: any = item;
                        const splittedFields = field.field.split('.');
                        // parse value until its null or reached end
                        splittedFields.every((subField) => {
                            value = value[subField];
                            if (value == null) return false;
                            return true;
                        });
                        itemData.push(
                            field.parseFunction
                                ? field.parseFunction(value)
                                : value instanceof Date
                                ? value.toISOString()
                                : value?.toString()
                        );
                    }
                });
                csvData.push(itemData);
            });

            filters['_id|gt'] = items[items.length - 1]._id;
        } else {
            continueRequests = false;
        }
    }

    const output = stringify(csvData, {
        header: true,
        columns: finalCsvHeaders,
    });
    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    downloadFromUrl(fileDownloadUrl, filename, '.csv');
    URL.revokeObjectURL(fileDownloadUrl);
}