import api from 'services/api';

const apiUrl = window.API_URL;

export const getOneProject = (
    id: string,
    options?: {
        signal?: AbortSignal;
    }
): Promise<Ether.TitlesReport.IProject | null> => {
    return new Promise<Ether.TitlesReport.IProject | null>(
        (resolve, reject) => {
            api.get<Ether.IApi<any>>(`${apiUrl}/list-project`, {
                signal: options?.signal,
                params: {
                    _id: id,
                },
            })
                .then((result) => {
                    const count = result.data.meta.payload_count;
                    if (count === 1) {
                        const project = result.data.payload[0];
                        resolve(project);
                    } else {
                        resolve(null);
                    }
                })
                .catch(reject);
        }
    );
};

export const listProjects = (options?: {
    signal?: AbortSignal;
    limit?: number;
    offset?: number;
    fields?: string[];
}): Promise<Ether.TitlesReport.IProject[]> => {
    return new Promise<Ether.TitlesReport.IProject[]>((resolve, reject) => {
        api.get<Ether.IApi<any>>(`${apiUrl}/list-project`, {
            signal: options?.signal,
            params: {
                limit: options?.limit ?? 10,
                offset: options?.offset ?? 0,
                _fields : options?.fields?.join(',')
            },
        })
            .then((result) => {
                const projects = result.data.payload;
                resolve(projects);
            })
            .catch(reject);
    });
};

export const listProjectsByGroupId = (
    group_id: string,
    options?: {
        signal?: AbortSignal;
    },
    limit: number = 10,
    offset: number = 0
): Promise<Ether.TitlesReport.IProject[]> => {
    return new Promise<Ether.TitlesReport.IProject[]>((resolve, reject) => {
        api.get<Ether.IApi<any>>(`${apiUrl}/list-project`, {
            signal: options?.signal,
            params: {
                'groups@oid': group_id,
                limit,
                offset,
            },
        })
            .then((result) => {
                const projects = result.data.payload;
                resolve(projects);
            })
            .catch(reject);
    });
};
