import DateBadge from 'components/Misc/DateBadge';
import OidBadge from 'components/Misc/OidBadge';
import { Column } from 'primereact/column';
import {
    DataTable,
    DataTableFilterMeta,
    DataTableSortMeta,
} from 'primereact/datatable';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import './style.css';
import { useRef, useState } from 'react';
import NoTotalPaginator from 'components/Form/NoTotalPaginator';
import { listLinkInboxByProject } from 'services/title-report/link-inbox';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import RowDataView from 'components/Misc/RowDataView';
import { useProjectQuery } from '..';

const ListLinksInbox = () => {
    const toastRef = useRef<Toast>(null);

    const params = useParams();

    const projectQuery = useProjectQuery();

    const [sort, setSort] = useState<{
        sortField: undefined | string;
        sortOrder: DataTableSortMeta['order'] | null;
    }>({
        sortField: undefined,
        sortOrder: null,
    });
    const [pageData, setPageData] = useState({
        page: 1,
        rows: 10,
    });
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        value: {
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                },
            ],
            operator: FilterOperator.AND,
        },
        domain: {
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                },
            ],
            operator: FilterOperator.AND,
        },
        captured_at: {
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.DATE_AFTER,
                },
            ],
            operator: FilterOperator.AND,
        },
        source: {
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                },
            ],
            operator: FilterOperator.AND,
        },
    });

    const [selectedLinkInbox, setSelectedLinkInbox] =
        useState<Ether.TitlesReport.ILinkInbox | null>(null);

    const linkInboxQuery = useQuery<Ether.TitlesReport.ILinkInbox[], Error>(
        ['list-link-inbox', pageData, filters, sort],
        async (): Promise<Ether.TitlesReport.ILinkInbox[]> => {
            if (!params.projectOid) throw new Error('no project oid supplied');
            return listLinkInboxByProject(params.projectOid, {
                limit: pageData.rows,
                offset: (pageData.page - 1) * pageData.rows,
                filters,
                sortField: sort.sortField,
                sortOrder: sort.sortOrder,
            });
        },
        {
            enabled: projectQuery?.isSuccess,
        }
    );

    const renderActionButtons = (rowData: Ether.TitlesReport.ILinkInbox) => {
        return (
            <Button
                icon='pi pi-search'
                className='p-button-outlined'
                onClick={() => setSelectedLinkInbox(rowData)}
            />
        );
    };

    return (
        <div>
            <RowDataView
                onHide={() => setSelectedLinkInbox(null)}
                visible={!!selectedLinkInbox}
                modelData={selectedLinkInbox}
            />
            <Toast ref={toastRef} />
            {projectQuery?.data && (
                <>
                    <h2>{projectQuery?.data.name} - Link Inbox</h2>
                    <NoTotalPaginator
                        page={pageData.page}
                        rows={pageData.rows}
                        onPageChange={setPageData}
                        disableNext={
                            !linkInboxQuery.data ||
                            linkInboxQuery.data.length < pageData.rows
                        }
                    />
                    {linkInboxQuery.error ? (
                        linkInboxQuery.error.message
                    ) : (
                        <DataTable
                            value={linkInboxQuery.data}
                            loading={linkInboxQuery.isLoading}
                            emptyMessage='No link inbox available'
                            filters={filters}
                            onFilter={(e) => setFilters(e.filters)}
                            onSort={(e) =>
                                setSort({
                                    sortField: e.sortField,
                                    sortOrder: e.sortOrder,
                                })
                            }
                            sortField={sort.sortField}
                            sortOrder={sort.sortOrder}
                            removableSort
                            lazy
                        >
                            <Column
                                field='_id'
                                header='OID'
                                body={(rowData) => (
                                    <OidBadge value={rowData._id} />
                                )}
                            />
                            <Column
                                field='value'
                                header='URL'
                                style={{ maxWidth: '40ch' }}
                                body={(rowData) => (
                                    <div
                                        style={{
                                            maxWidth: '40ch',
                                            maxHeight: '100px',
                                            wordWrap: 'break-word',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {rowData.value ?? (
                                            <Badge value='null' />
                                        )}
                                    </div>
                                )}
                                filter
                                showFilterOperator={false}
                                showAddButton={false}
                            />
                            <Column
                                field='domain'
                                header='Domain'
                                filter
                                showFilterOperator={false}
                                showAddButton={false}
                                body={(rowData) =>
                                    rowData.domain ?? <Badge value='null' />
                                }
                            />
                            <Column
                                field='captured_at'
                                header='Captured at'
                                body={(rowData) => (
                                    <DateBadge value={rowData.captured_at} />
                                )}
                                filter
                                showFilterOperator={false}
                                showAddButton={false}
                                filterType='date'
                                dataType='date'
                                sortable
                            />
                            <Column
                                field='source'
                                header='Source'
                                filter
                                showFilterOperator={false}
                                showAddButton={false}
                                body={(rowData) =>
                                    rowData.source ?? <Badge value='null' />
                                }
                            />
                            <Column
                                field='source_type'
                                header='Source Type'
                                body={(rowData) =>
                                    rowData.source_type ?? (
                                        <Badge value='null' />
                                    )
                                }
                            />
                            <Column
                                field='rejected_tags'
                                header='Rejected tags'
                                body={(rowData) => (
                                    <div
                                        style={{
                                            maxWidth: '40ch',
                                            maxHeight: '100px',
                                            wordWrap: 'break-word',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {rowData.rejected_tags?.join(', ')}
                                    </div>
                                )}
                            />
                            <Column
                                header='Actions'
                                body={renderActionButtons}
                            />
                        </DataTable>
                    )}
                </>
            )}
        </div>
    );
};

export default ListLinksInbox;
