import { ProgressSpinner } from 'primereact/progressspinner';
import renderIsRequired from '../utils/renderIsRequired';

import './style.css';

interface IDatepicker {
    label?: string;
    labelStyle?: React.CSSProperties;
    loading?: boolean;
    error?: string;
    value?: Date | null;
    onChange?(value: Date | null): void;
    name?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    required?: boolean;
    wrapperStyle?: React.CSSProperties;
    type?: 'date' | 'datetime-local';
}

const CustomDatepicker: React.FC<IDatepicker> = ({
    label,
    labelStyle,
    value,
    loading = false,
    error,
    name,
    onChange,
    required,
    ...rest
}) => {
    return (
        <div className='datepicker' style={rest.wrapperStyle}>
            <span>
                <label
                    htmlFor={name}
                    style={{
                        marginRight: '8px',
                        display: label ? 'inherit' : 'none',
                        ...labelStyle,
                    }}
                >
                    {label}
                    {renderIsRequired(required)}
                </label>
                <div className='wrap-content'>
                    <input
                        className={[
                            'ether-datetime',
                            rest.disabled || loading ? 'p-disabled' : '',
                        ].join(' ')}
                        type={rest.type ?? 'date'}
                        disabled={rest.disabled || loading}
                        value={
                            value
                                ? !rest.type || rest.type === 'date'
                                    ? value.toISOString().split('T')[0]
                                    : value.toISOString().split(':00.000Z')[0]
                                : ''
                        }
                        onChange={(e) =>
                            onChange &&
                            (e.target.value
                                ? onChange(new Date(e.target.value))
                                : onChange(null))
                        }
                        {...rest}
                    />
                    {loading && (
                        <ProgressSpinner
                            style={{
                                width: '20px',
                                height: '20px',
                                margin: '0 0',
                            }}
                            strokeWidth='6'
                            className='custom-spinner'
                        />
                    )}
                </div>
                {error && (
                    <small className='p-error block input-text-error'>
                        {error}
                    </small>
                )}
            </span>
        </div>
    );
};

export default CustomDatepicker;