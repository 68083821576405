import CustomInputText from 'components/Form/CustomInputText';
import { useToast } from 'hooks/useToast';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    updateInboxRule,
    getInboxRule,
} from 'services/title-report/inbox-rules';

const UpdateInboxRule = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const params = useParams<{ name: string }>() as { name: string };

    const [formData, setFormData] = useState({
        domain: '',
        regex: '',
    });

    const inboxRuleQuery = useQuery<
        Ether.TitlesReport.IInboxRules['rules'][0],
        Error
    >(
        ['get-inbox-rule', params.name],
        async () => {
            const data = await getInboxRule(params.name);
            if (!data) {
                navigate('..');
                throw Error('not found');
            }
            return data;
        },
        {
            retry: 0,
            onSuccess: (data) => {
                setFormData(data);
            },
        }
    );

    const updateRuleMutation = useMutation<
        Ether.TitlesReport.IInboxRules['rules'][0],
        Error,
        Omit<Ether.TitlesReport.IInboxRules['rules'][0], 'name'>
    >(async (data) => updateInboxRule(params.name, data), {
        onSuccess: () => {
            toast?.show({
                severity: 'success',
                summary: `Updated rule ${params.name}`,
            });
            navigate('..');
        },
        onError: (err) => {
            toast?.show({
                severity: 'error',
                summary: 'Error updating',
                detail: err.message,
                life: 10000,
            });
        },
    });

    const handleSubmit = () => {
        updateRuleMutation.mutate({
            domain: formData.domain,
            regex: formData.regex,
        });
    };

    return (
        <div>
            <Link to='..'>
                <Button label='Go back' />
            </Link>
            {inboxRuleQuery.isLoading ? (
                <h2>Loading...</h2>
            ) : (
                <>
                    <h2>Update Inbox Rule {params.name}</h2>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '8px',
                        }}
                    >
                        <CustomInputText
                            label='Domain'
                            name='domain'
                            value={formData.domain}
                            onChange={(e) =>
                                setFormData((old) => ({
                                    ...old,
                                    domain: e.target.value,
                                }))
                            }
                        />
                        <CustomInputText
                            label='Regex'
                            name='regex'
                            value={formData.regex}
                            onChange={(e) =>
                                setFormData((old) => ({
                                    ...old,
                                    regex: e.target.value,
                                }))
                            }
                        />
                        <Button
                            label='Update'
                            icon='pi pi-pencil'
                            onClick={handleSubmit}
                            disabled={
                                formData.domain === '' || formData.regex === ''
                            }
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default UpdateInboxRule;
