import DateBadge from 'components/Misc/DateBadge';
import OidBadge from 'components/Misc/OidBadge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
    DataTable,
    DataTableFilterMeta,
    DataTableSortMeta,
} from 'primereact/datatable';
import { RadioButton } from 'primereact/radiobutton';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
    downloadReport,
    generateReportForProject,
    listReportsByProject,
    ReportType,
    toggleReportAPI,
} from 'services/title-report/report';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';

import './style.css';
import { Badge, BadgeProps } from 'primereact/badge';
import downloadFromUrl from 'utils/downloadFromUrl';
import { useRef, useState } from 'react';
import NoTotalPaginator from 'components/Form/NoTotalPaginator';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import ReportProgressUpdater from './components/ReportProgressUpdater';
import { getMyInfo } from 'services/title-report/me';
import { useProjectQuery } from '..';

const ListReports = () => {
    const toastRef = useRef<Toast>(null);
    const cancelRef = useRef<AbortController | null>(null);

    const myInfo = useQuery<Ether.IMe, Error>(
        ['me'],
        (): Promise<Ether.IMe> => getMyInfo()
    );

    const params = useParams<{
        projectOid: string;
    }>() as {
        projectOid: string;
    };

    const projectQuery = useProjectQuery();

    const [pageData, setPageData] = useState({
        page: 1,
        rows: 10,
    });
    const [sort, setSort] = useState<{
        sortField: undefined | string;
        sortOrder: DataTableSortMeta['order'] | null;
    }>({
        sortField: 'created_at',
        sortOrder: -1,
    });
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        created_at: {
            constraints: [
                {
                    value: null,
                    matchMode: FilterMatchMode.DATE_IS,
                },
            ],
            operator: FilterOperator.AND,
        },
        status: {
            value: null,
            matchMode: FilterMatchMode.EQUALS,
        },
        type: {
            value: null,
            matchMode: FilterMatchMode.EQUALS,
        },
    });

    const [generateReportDialogVisible, setGenerateReportDialogVisible] =
        useState(false);
    const [hoveredReport, setHoveredReport] =
        useState<Ether.TitlesReport.IReport | null>(null);

    const [downloadingReport, setDownloadingReport] = useState<{
        id: string | null;
        downloading: boolean;
        progress: number;
        type: 'source' | 'unwinded' | null;
    }>({
        id: null,
        downloading: false,
        progress: 0,
        type: null,
    });

    const reportQuery = useQuery<Ether.TitlesReport.IReport[], Error>(
        ['list-report', pageData, filters, sort],
        async (): Promise<Ether.TitlesReport.IReport[]> => {
            return listReportsByProject(params.projectOid, {
                limit: pageData.rows,
                offset: (pageData.page - 1) * pageData.rows,
                filters,
                sortField: sort.sortField,
                sortOrder: sort.sortOrder,
            });
        }
    );

    const reportMutation = useMutation<
        string,
        Error,
        {
            type: ReportType;
        }
    >(
        ({ type }) => {
            if (!params.projectOid) throw new Error('no project oid supplied');
            return generateReportForProject(params.projectOid, type);
        },
        {
            onSuccess: () => {
                toastRef.current?.show({
                    severity: 'success',
                    summary: 'Report generated',
                });
                reportQuery.refetch();
            },
            onError: (err) => {
                toastRef.current?.show({
                    severity: 'error',
                    summary: 'Failed to generate report',
                    detail: err.toString(),
                });
            },
            onSettled: () => {
                cancelRef.current = null;
                setGenerateReportDialogVisible(false);
            },
        }
    );

    const reportApiMutation = useMutation<
        void,
        Error,
        {
            reportId: string;
            enable: boolean;
        }
    >(
        ({ reportId, enable }) => {
            if (!params.projectOid) throw new Error('no project oid supplied');
            return toggleReportAPI(reportId, enable);
        },
        {
            onSuccess: (_, variables) => {
                toastRef.current?.show({
                    severity: 'success',
                    summary: variables.enable
                        ? 'ReportAPI enabled'
                        : 'ReportAPI disabled',
                });
                reportQuery.refetch();
            },
            onError: (err, variables) => {
                toastRef.current?.show({
                    severity: 'error',
                    summary: `Failed to ${
                        variables.enable ? 'enable' : 'disable'
                    } ReportAPI`,
                    detail: err.toString(),
                });
            },
            onSettled: () => {
                cancelRef.current = null;
                setGenerateReportDialogVisible(false);
            },
        }
    );

    const downloadFile = (report_id: string, type?: 'source' | 'unwinded') => {
        cancelRef.current = new AbortController();
        setDownloadingReport({
            id: report_id,
            downloading: true,
            progress: 0,
            type: type ?? null,
        });
        downloadReport(report_id, {
            onDownloadProgress: (progress) => {
                setDownloadingReport((old) => ({ ...old, progress }));
            },
            type: type,
            signal: cancelRef.current.signal,
        })
            .then((data) => {
                const url = URL.createObjectURL(data);
                downloadFromUrl(url, `${report_id}_${type}`, '.csv');
            })
            .catch((err) => {
                toastRef.current?.show({
                    summary: 'Failed to download',
                    detail: err.toString(),
                    severity: 'error',
                });
            })
            .finally(() => {
                cancelRef.current = null;
                setDownloadingReport({
                    id: null,
                    downloading: false,
                    progress: 0,
                    type: null,
                });
            });
    };

    const renderStatus = (rowData: Ether.TitlesReport.IReport) => {
        let severity: BadgeProps['severity'] = 'info';

        switch (rowData.status) {
            case 'done':
                severity = 'success';
                break;
            case 'error':
                severity = 'danger';
                break;
            case 'processing':
                severity = 'warning';
                break;
            case 'new':
                severity = 'info';
                break;
        }

        return <Badge value={rowData.status} severity={severity} />;
    };

    const renderReportAPIStatus = (rowData: Ether.TitlesReport.IReport) => {
        let severity: BadgeProps['severity'] = rowData.report_api?.is_active
            ? 'success'
            : 'danger';
        let value = rowData.report_api?.is_active ? 'Yes' : 'No';

        return <Badge value={value} severity={severity} />;
    };

    const getProgress = (current: number, total: number) => {
        return current > total
            ? 100
            : Math.round((current / total) * 10000) / 100;
    };

    const renderActionButtons = (rowData: Ether.TitlesReport.IReport) => {
        const numberLen = downloadingReport.progress
            .toString()
            .split('.')[0].length;
        return (
            <div>
                <div className='action-buttons-wrap'>
                    <Button
                        className='p-button-outlined tooltip-button'
                        icon='pi pi-download'
                        loading={downloadingReport.id === rowData._id}
                        disabled={
                            rowData.status !== 'done' ||
                            downloadingReport.downloading
                        }
                        onMouseEnter={() => setHoveredReport(rowData)}
                    />
                    {downloadingReport.id === rowData._id && (
                        <Button
                            className='p-button-outlined'
                            tooltip={
                                downloadingReport.id === rowData._id
                                    ? ''
                                    : 'Cancel'
                            }
                            icon='pi pi-ban'
                            onClick={() => {
                                cancelRef.current?.abort();
                            }}
                        />
                    )}
                    {myInfo.data?.is_superadmin && (
                        <Button
                            className='p-button-outlined'
                            icon={
                                rowData.report_api?.is_active
                                    ? 'pi pi-times'
                                    : 'pi pi-check'
                            }
                            label={
                                rowData.report_api?.is_active
                                    ? 'Disable ReportAPI'
                                    : 'Enable ReportAPI'
                            }
                            disabled={reportApiMutation.isLoading}
                            loading={
                                reportApiMutation.isLoading &&
                                hoveredReport?._id === rowData._id
                            }
                            onMouseEnter={() => setHoveredReport(rowData)}
                            onClick={() =>
                                reportApiMutation.mutate({
                                    reportId: rowData._id,
                                    enable: !rowData.report_api?.is_active,
                                })
                            }
                        />
                    )}
                </div>
                {downloadingReport.id === rowData._id && (
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                marginBottom: '8px',
                            }}
                        >
                            {downloadingReport.progress.toPrecision(
                                numberLen + 2
                            )}{' '}
                            MB
                        </div>
                        <div style={{ marginTop: '24px' }}>
                            {downloadingReport.type === 'source' &&
                            rowData.meta?.report?.size_mb ? (
                                <ProgressBar
                                    value={getProgress(
                                        downloadingReport.progress,
                                        rowData.meta.report.size_mb
                                    )}
                                />
                            ) : downloadingReport.type === 'unwinded' &&
                              rowData.meta?.unwinded?.size_mb ? (
                                <ProgressBar
                                    value={getProgress(
                                        downloadingReport.progress,
                                        rowData.meta.unwinded.size_mb
                                    )}
                                />
                            ) : (
                                <ProgressBar mode='indeterminate' />
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    };

    const GenerateReportModal = () => {
        const [reportType, setReportType] = useState<ReportType>(
            ReportType.NORMAL
        );

        return (
            <Dialog
                header='Generate new report'
                visible={generateReportDialogVisible}
                onHide={() => setGenerateReportDialogVisible(false)}
                className='generate-report'
            >
                <div className='report-type'>
                    <span>Select report type</span>
                    <div className='option'>
                        <RadioButton
                            inputId='report-normal'
                            value={ReportType.NORMAL}
                            name='normal'
                            onChange={(e) => setReportType(e.value)}
                            checked={reportType === ReportType.NORMAL}
                        />
                        <label htmlFor='report-normal'>Normal</label>
                    </div>
                    <div className='option'>
                        <RadioButton
                            inputId='report-inbox'
                            value={ReportType.INBOX}
                            name='inbox'
                            onChange={(e) => setReportType(e.value)}
                            checked={reportType === ReportType.INBOX}
                        />
                        <label htmlFor='report-inbox'>Inbox</label>
                    </div>
                </div>
                <Button
                    label='Generate report'
                    loading={reportMutation.isLoading}
                    onClick={() =>
                        reportMutation.mutateAsync({ type: reportType })
                    }
                />
            </Dialog>
        );
    };

    return (
        <div>
            <Toast ref={toastRef} />
            <Tooltip
                target='.tooltip-button'
                autoHide={false}
                position='left'
                style={{
                    display: downloadingReport.downloading ? 'none' : '',
                }}
            >
                {hoveredReport?.report_file_data && (
                    <Button
                        className='p-button-outlined'
                        label='source'
                        icon='pi pi-download'
                        loading={downloadingReport.type === 'source'}
                        disabled={downloadingReport.downloading}
                        onClick={() =>
                            hoveredReport &&
                            downloadFile(hoveredReport._id, 'source')
                        }
                        style={{ marginRight: '8px' }}
                    />
                )}
                {hoveredReport?.report_unwinded_file_data && (
                    <Button
                        className='p-button-outlined'
                        label='unwinded'
                        icon='pi pi-download'
                        loading={downloadingReport.type === 'unwinded'}
                        disabled={downloadingReport.downloading}
                        onClick={() =>
                            hoveredReport &&
                            downloadFile(hoveredReport._id, 'unwinded')
                        }
                    />
                )}
            </Tooltip>
            {projectQuery?.data && (
                <>
                    <GenerateReportModal />
                    <h2>{projectQuery?.data.name} - Reports</h2>
                    <Button
                        label='New report'
                        icon='pi pi-plus'
                        loading={reportMutation.isLoading}
                        onClick={() => setGenerateReportDialogVisible(true)}
                    />
                    <NoTotalPaginator
                        page={pageData.page}
                        rows={pageData.rows}
                        onPageChange={setPageData}
                        disableNext={
                            !reportQuery.data ||
                            reportQuery.data.length < pageData.rows
                        }
                    />
                    {reportQuery.error ? (
                        reportQuery.error.message
                    ) : (
                        <DataTable
                            value={reportQuery.data}
                            loading={reportQuery.isLoading}
                            emptyMessage='No reports available'
                            filters={filters}
                            onFilter={(e) => setFilters(e.filters)}
                            onSort={(e) =>
                                setSort({
                                    sortField: e.sortField,
                                    sortOrder: e.sortOrder,
                                })
                            }
                            sortField={sort.sortField}
                            sortOrder={sort.sortOrder}
                            removableSort
                            lazy
                        >
                            <Column
                                field='_id'
                                header='OID'
                                body={(rowData) => (
                                    <OidBadge value={rowData._id} />
                                )}
                            />
                            <Column
                                field='created_at'
                                header='Created at'
                                body={(rowData) => (
                                    <DateBadge value={rowData.created_at} />
                                )}
                                sortable
                                filter
                                filterType='date'
                                dataType='date'
                                showFilterOperator={false}
                                showAddButton={false}
                            />
                            <Column
                                field='status'
                                header='Status'
                                body={renderStatus}
                                filter
                                filterElement={(options) => (
                                    <Dropdown
                                        placeholder='Select status'
                                        value={options.value}
                                        onChange={(e) =>
                                            options.filterCallback(
                                                e.target.value
                                            )
                                        }
                                        options={[
                                            {
                                                label: 'Done',
                                                value: 'done',
                                            },
                                            {
                                                label: 'Error',
                                                value: 'error',
                                            },
                                            {
                                                label: 'New',
                                                value: 'new',
                                            },
                                            {
                                                label: 'Pending',
                                                value: 'pending',
                                            },
                                            {
                                                label: 'Processing',
                                                value: 'processing',
                                            },
                                        ]}
                                    />
                                )}
                                showFilterOperator={false}
                                showFilterMenuOptions={false}
                                showAddButton={false}
                            />
                            <Column
                                field='type'
                                header='Type'
                                body={(rowData) => (
                                    <Badge
                                        value={rowData.type ?? 'normal'}
                                        severity='info'
                                    />
                                )}
                                filter
                                filterElement={(options) => (
                                    <Dropdown
                                        placeholder='Select type'
                                        value={options.value}
                                        onChange={(e) =>
                                            options.filterCallback(
                                                e.target.value
                                            )
                                        }
                                        options={[
                                            {
                                                label: 'Inbox',
                                                value: 'inbox',
                                            },
                                            {
                                                label: 'Normal',
                                                value: 'normal',
                                            },
                                        ]}
                                    />
                                )}
                                showFilterOperator={false}
                                showFilterMenuOptions={false}
                                showAddButton={false}
                            />
                            <Column
                                field='progress'
                                header='Progress'
                                body={(rowData: Ether.TitlesReport.IReport) => (
                                    <ReportProgressUpdater
                                        report={rowData}
                                        projectId={params.projectOid}
                                        onReportSettled={reportQuery.refetch}
                                    />
                                )}
                            />
                            <Column
                                header='ReportAPI?'
                                body={renderReportAPIStatus}
                            />
                            <Column
                                header='Actions'
                                body={renderActionButtons}
                            />
                        </DataTable>
                    )}
                </>
            )}
        </div>
    );
};

export default ListReports;
