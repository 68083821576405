import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

const Metrics = () => {
    return (
        <div>
            <h2>Available metrics</h2>
            <div style={{ display: 'flex', gap: '8px' }}>
                <Link to='link-count'>
                    <Button label='Link count' />
                </Link>
                <Link to='inbox-count'>
                    <Button label='Inbox count' />
                </Link>
            </div>
        </div>
    );
};

export default Metrics;
