import axios from 'axios';
import {
    DataTableFilterMeta,
    DataTableFilterMetaData,
    DataTableOperatorFilterMetaData,
    DataTableSortMeta,
} from 'primereact/datatable';
import api from 'services/api';
import { handleFilterMatchMode } from 'services/utils';

const apiUrl = window.API_URL;

export enum ReportType {
    NORMAL = 'normal',
    INBOX = 'inbox',
}

const handleDataTableFilterMeta = (filters?: DataTableFilterMeta) => {
    if (!filters) return {};
    let paramFilters: { [key: string]: string | number } = {};
    if (filters['created_at']) {
        const filterMeta = filters[
            'created_at'
        ] as DataTableOperatorFilterMetaData;
        paramFilters = {
            ...paramFilters,
            ...handleFilterMatchMode(
                'created_at',
                filterMeta.constraints[0]?.value,
                filterMeta.constraints[0].matchMode
            ),
        };
    }
    if (filters['status']) {
        const filterMeta = filters['status'] as DataTableFilterMetaData;
        paramFilters['status'] = filterMeta.value;
    }
    if (filters['type']) {
        const filterMeta = filters['type'] as DataTableFilterMetaData;
        if (filterMeta.value === 'normal') paramFilters['type|ne'] = 'inbox';
        else if (filterMeta.value === 'inbox') paramFilters['type'] = 'inbox';
    }
    return paramFilters;
};

export const listReportsByProject = (
    project_id: string,
    options?: {
        signal?: AbortSignal;
        limit?: number;
        offset?: number;

        filters?: DataTableFilterMeta;
        sortField?: string | null;
        sortOrder?: DataTableSortMeta['order'] | null;
    }
): Promise<Ether.TitlesReport.IReport[]> => {
    const filters = handleDataTableFilterMeta(options?.filters);

    let order: string | null = null;
    if (options?.sortField && options.sortOrder) {
        if (options.sortOrder === 1) {
            order = options.sortField;
        } else if (options.sortOrder === -1) {
            order = '-' + options.sortField;
        }
    }
    if (order) filters['order'] = order;

    return new Promise<Ether.TitlesReport.IReport[]>((resolve, reject) => {
        api.get<Ether.IApi<any>>(`${apiUrl}/list-report`, {
            signal: options?.signal,
            params: {
                project_id,
                limit: options?.limit ? options.limit : 10,
                offset: options?.offset,
                ...filters,
            },
        })
            .then((result) => {
                const reports = result.data.payload;
                resolve(reports);
            })
            .catch(reject);
    });
};

export const generateReportForProject = (
    project_id: string,
    type: ReportType
): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        api.post<
            Ether.IApi<{
                project: string;
                created: boolean;
                report: string;
            }>
        >(`${apiUrl}/generate-report`, [
            {
                project_id,
                type,
            },
        ])
            .then((result) => {
                const data = result.data.payload[0];
                if (data.created) resolve(data.report);
                reject(new Error('failed to create report'));
            })
            .catch(reject);
    });
};

export const downloadReport = (
    report_id: string,
    options?: {
        onDownloadProgress?(progress: number): void;
        type?: 'source' | 'unwinded';
        signal?: AbortSignal;
    }
): Promise<any> => {
    return new Promise<string>((resolve, reject) => {
        axios
            .get<string>(`${apiUrl}/download-report`, {
                responseType: 'blob',
                maxBodyLength: 10000000000,
                timeout: 1000 * 60 * 60,
                timeoutErrorMessage: 'Download timeout',
                onDownloadProgress: (e) => {
                    if (!options?.onDownloadProgress) return;
                    const progress = Math.round(e.loaded / 10000) / 100;
                    options.onDownloadProgress(progress);
                },
                signal: options?.signal,
                params: {
                    report_oid: report_id,
                    type: options?.type,
                },
                headers: api.defaults.headers as any,
            })
            .then((result) => {
                resolve(result.data);
            })
            .catch(reject);
    });
};

export const getOneReport = (
    reportId: string,
    projectId: string,
    options?: {
        signal?: AbortSignal;
        fields?: string[];
    }
): Promise<Ether.TitlesReport.IReport | null> => {
    return new Promise<Ether.TitlesReport.IReport | null>((resolve, reject) => {
        api.get<Ether.IApi<any>>(`${apiUrl}/list-report`, {
            signal: options?.signal,
            params: {
                project_id: projectId,
                _id: reportId,
                _fields: options?.fields?.join(','),
                limit: 1,
            },
        })
            .then((result) => {
                if (result.data.payload.length > 0)
                    resolve(result.data.payload[0]);
                else resolve(null);
            })
            .catch(reject);
    });
};

export const toggleReportAPI = (
    reportId: string,
    enable: boolean
): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        api.post(`${apiUrl}/update-report/${reportId}`, {
            report_api: {
                is_active: enable,
            },
        })
            .then(() => {
                resolve();
            })
            .catch(reject);
    });
};
