import DateBadge from 'components/Misc/DateBadge';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getOneTitleFromSlug } from 'services/titlesdb/title';
import { Player, Video, DefaultUi } from '@vime/react';

import '@vime/core/themes/default.css';
import './style.css';
import LoadingSpinner from 'components/Misc/LoadingSpinner';
import OidBadge from 'components/Misc/OidBadge';
import { Tooltip } from 'primereact/tooltip';

const pageTime = Date.now();

interface IMatchValidation {
    link: Ether.TitlesReport.ILink;
    onValidate(link_id: string, accepted: boolean | null): void;
    loading?: boolean;
    disabled?: boolean;
    originalVideoSource: string;
    sampleVideoSource: string;
    videosLoading?: boolean;
}

const MatchValidation: React.FC<IMatchValidation> = ({
    link,
    loading,
    disabled,
    onValidate,
    originalVideoSource,
    sampleVideoSource,
    videosLoading,
}) => {
    const [dialogTitleVisible, setDialogTitleVisible] = useState(false);
    const filesUri = window.EXTERNAL_URI_FILE;
    const {
        data: titleData,
        isLoading: isTitleLoading,
        error: titleError,
    } = useQuery(
        ['get-one-title', link.titleinfo?.slug],
        () =>
            link.titleinfo?.slug
                ? getOneTitleFromSlug(link.titleinfo.slug)
                : null,
        {
            enabled: !!link.titleinfo?.slug,
            retry: 2,
        }
    );

    const renderValidation = (value?: boolean | null) => {
        return value ? (
            <Badge value='Match' severity='info' />
        ) : (
            <Badge value='No match' severity='info' />
        );
    };

    const getFullTitle = (
        titleslug?: string | null,
        season?: string | null,
        episode?: string | null
    ) => {
        let title = titleslug;
        if (!titleError && titleData) {
            title = titleData?.name;
        }
        return (
            `${title}` +
            (season && season !== '' ? `, Season ${season}` : '') +
            (episode && episode !== '' ? `, Episode ${episode}` : '')
        );
    };

    const TitleInfoDialog = () => {
        const getAlternativeNames = () => {
            const alternativeNames: string[] = [];
            if (!titleData) return alternativeNames;
            for (const titleInfo of titleData.title) {
                const index = alternativeNames.findIndex(
                    (item) => item === titleInfo.value
                );
                if (index === -1 && titleInfo.value !== titleData.name)
                    alternativeNames.push(titleInfo.value);
            }
            return alternativeNames;
        };

        return (
            <Dialog
                visible={dialogTitleVisible}
                onHide={() => setDialogTitleVisible(false)}
                header={getFullTitle(
                    link.titleinfo?.slug,
                    link.titleinfo?.season,
                    link.titleinfo?.episode
                )}
                style={{ maxWidth: '60%' }}
            >
                {isTitleLoading
                    ? 'Loading title information...'
                    : titleError
                    ? 'Failed to load title data'
                    : titleData && (
                          <div
                              style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '8px',
                              }}
                          >
                              <div>
                                  <b>Name: </b>
                                  <span>{titleData.name}</span>
                              </div>
                              {getAlternativeNames().length > 0 && (
                                  <div>
                                      <b>AKA: </b>
                                      <ul>
                                          {getAlternativeNames().map((item) => (
                                              <li key={item}>{item}</li>
                                          ))}
                                      </ul>
                                  </div>
                              )}
                              <div>
                                  <b>Release date: </b>
                                  <span>
                                      {titleData.release_date
                                          ? titleData.release_date.toDateString()
                                          : 'unknown'}
                                  </span>
                              </div>
                              <div>
                                  <b>Type: </b>
                                  <span>{titleData.type}</span>
                              </div>
                              {titleData.imdb_id && (
                                  <div>
                                      <b>IMDB: </b>
                                      <a
                                          href={`https://www.imdb.com/title/${titleData.imdb_id}`}
                                          target='_blank'
                                          rel='noreferrer'
                                      >
                                          {`https://www.imdb.com/title/${titleData.imdb_id}`}
                                      </a>
                                  </div>
                              )}
                              {titleData.tmdb_id && (
                                  <div>
                                      <b>TMDB: </b>
                                      <a
                                          href={`https://www.themoviedb.org/${titleData.type}/${titleData.tmdb_id}`}
                                          target='_blank'
                                          rel='noreferrer'
                                      >
                                          {`https://www.themoviedb.org/${titleData.type}/${titleData.tmdb_id}`}
                                      </a>
                                  </div>
                              )}
                              {titleData.production_companies &&
                                  titleData.production_companies.length > 0 && (
                                      <div>
                                          <b>Production companies: </b>
                                          <span>
                                              {titleData.production_companies.join(
                                                  ', '
                                              )}
                                          </span>
                                      </div>
                                  )}
                              {titleData.synopsis &&
                                  titleData.synopsis.length > 0 && (
                                      <div>
                                          <b>Synopsis: </b>
                                          <ul>
                                              {titleData.synopsis
                                                  .filter(
                                                      (item) =>
                                                          item.value &&
                                                          item.value !== ''
                                                  )
                                                  .map((item) => (
                                                      <li
                                                          key={
                                                              item.language +
                                                              item.value
                                                          }
                                                          style={{
                                                              marginBottom:
                                                                  '8px',
                                                          }}
                                                      >
                                                          <b>
                                                              [{item.language}]
                                                          </b>{' '}
                                                          {item.value}
                                                      </li>
                                                  ))}
                                          </ul>
                                      </div>
                                  )}
                          </div>
                      )}
            </Dialog>
        );
    };
    
    const VideoPlayer: React.FC<{
        source: string;
    }> = ({ source }) => {
        return (
            <Player key={source} autopause={false}>
                <Video>
                    <source
                        type='video/mp4'
                        src={`${filesUri}/download/${source}?t=${pageTime}`}
                    />
                </Video>
                <DefaultUi />
            </Player>
        );
    };

    const numberToPercentage = (value: number | undefined) => {
        if (value == null) return '-';
        const percentage = value * 100;
        const roundedPercentage = Math.round(percentage * 100) / 100;
        const formattedPercentage = roundedPercentage
            .toFixed(2)
            .replace('/.?0+$/', '');
        return formattedPercentage + '%';
    };

    return (
        <div className='match-validation-wrapper'>
            <TitleInfoDialog />
            <div className='grid-group header'>
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setDialogTitleVisible(true)}
                >
                    <b>Title: </b>
                    <span>
                        {getFullTitle(
                            link.titleinfo?.slug,
                            link.titleinfo?.season,
                            link.titleinfo?.episode
                        )}
                    </span>
                </div>
            </div>
            <div className='grid-group metadata'>
                <div>
                    <b>OID: </b>
                    <OidBadge value={link._id} />
                </div>
                <div>
                    <b>Created at: </b>
                    <DateBadge value={link.created_at} />
                </div>
                <div>
                    <b>VideoID Response: </b>
                    <span>{renderValidation(link.videoid_data.is_match)}</span>
                </div>
                <div>
                    <b>Match done at: </b>
                    {link.validation.checks.length > 0 ? (
                        <DateBadge
                            value={
                                link.validation.checks[
                                    link.validation.checks.length - 1
                                ].checked_at
                            }
                        />
                    ) : (
                        <Badge value='null' />
                    )}
                </div>
                <div>
                    <b>Match score</b>
                    <Tooltip target='.match-data-score' autoHide={false} />
                    <Badge
                        className='match-data-score'
                        data-pr-tooltip={
                            link.videoid_data.match_data?.score as undefined
                        }
                        severity='info'
                        value={numberToPercentage(
                            link.videoid_data.match_data?.score
                        )}
                    />
                </div>
            </div>
            <div className='grid-group video'>
                {videosLoading ? (
                    <div>
                        <LoadingSpinner /> Loading videos...{' '}
                    </div>
                ) : (
                    <>
                        <div className='original-video'>
                            {originalVideoSource !== '' ? (
                                <>
                                    <h3>New video</h3>
                                    <VideoPlayer source={originalVideoSource} />
                                </>
                            ) : (
                                'Original video link not found'
                            )}
                        </div>
                        <div className='sample-video'>
                            {sampleVideoSource !== '' ? (
                                <>
                                    <h3>Reference</h3>
                                    <VideoPlayer source={sampleVideoSource} />
                                </>
                            ) : (
                                'Sample video source not found'
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className='grid-group footer'>
                <h3>Are both videos equal?</h3>
                <div className='action-buttons'>
                    <Button
                        label='Yes'
                        severity='success'
                        loading={loading}
                        disabled={disabled}
                        onClick={() => onValidate(link._id, true)}
                    />
                    <Button
                        label='No'
                        severity='danger'
                        loading={loading}
                        disabled={disabled}
                        onClick={() => onValidate(link._id, false)}
                    />
                </div>
            </div>
        </div>
    );
};

export default MatchValidation;
