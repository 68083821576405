import React from 'react';

import { Button } from 'primereact/button';

import './style.css';

interface IDateBadge {
    value: Date | null;
    tooltipText?: string;
    hideTooltip?: boolean;
}

const DateBadge: React.FC<IDateBadge> = ({
    value,
    tooltipText,
    hideTooltip,
}) => {
    return (
        <Button
            tooltipOptions={{ autoHide: false }}
            className='date-badge p-button-rounded p-button-info'
            label={value ? value.toISOString().substring(0, 10) : 'null'}
            tooltip={
                hideTooltip
                    ? undefined
                    : tooltipText ?? (value ? value.toISOString() : 'null')
            }
        />
    );
};

export default DateBadge;
