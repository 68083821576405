import api from 'services/api';

const apiUrl = window.EXTERNAL_URI_TITLEDB;

export const getOneTitleFromSlug = (
    title_slug: string,
    options?: {
        signal ?: AbortSignal,
    }
): Promise<Ether.TitleDB.ITitle | null> => {
    return new Promise<Ether.TitleDB.ITitle | null>((resolve, reject) => {
        api.get<Ether.IApi<any>>(`${apiUrl}/list-title`, {
            signal: options?.signal,
            params: {
                'slug': title_slug,
                limit: 1,
            },
        })
            .then((result) => {
                const count = result.data.meta.payload_count;
                if (count === 1) {
                    const title = result.data.payload[0];
                    resolve(title);
                } else {
                    resolve(null);
                }
            })
            .catch(reject);
    });
};
