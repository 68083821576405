import { Badge } from 'primereact/badge';

import DateBadge from 'components/Misc/DateBadge';

import { countLinks } from 'services/title-report/link';

import './style.css';
import GenericCountScreen from '../../components/GenericCountScreen';
import ProjectsDropdown from '../../components/ProjectsDropdown';

const LinkCount = () => {
    return (
        <GenericCountScreen
            cacheKey='link'
            title='Link count'
            groupByOptions={[
                {
                    label: 'Captured at',
                    value: 'captured_at|date',
                    body: (row) => {
                        if (!row['captured_at|date'])
                            return <Badge value='null' />;
                        return (
                            <DateBadge
                                hideTooltip
                                value={new Date(row['captured_at|date'])}
                            />
                        );
                    },
                },
                {
                    label: 'Title slug',
                    value: 'titleinfo.slug',
                    field: 'titleinfo_slug',
                    isDefault : true,
                },
                {
                    label: 'Last validated at',
                    value: 'validation.last_check_at|date',
                    field: 'validation_last_check_at|date',
                    body: (row) => {
                        if (!row['validation_last_check_at|date'])
                            return <Badge value='null' />;
                        return (
                            <DateBadge
                                hideTooltip
                                value={
                                    new Date(
                                        row['validation_last_check_at|date']
                                    )
                                }
                            />
                        );
                    },
                },
                {
                    label: 'Source',
                    value: 'source',
                },
            ]}
            fetchDataCountFn={countLinks}
            filterHandlers={[
                {
                    defaultValue: [],
                    key: 'titleinfo.slug|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Title Slugs',
                },
                {
                    defaultValue: 'any',
                    key: 'onoff_data.is_active',
                    handler: (v) => {
                        if (v !== 'any') return (v === 'on').toString();
                        return null;
                    },
                    label: 'OnOff Status',
                    type: 'dropdown',
                    options: [
                        { value: 'any', label: 'Any' },
                        { value: 'on', label: 'On' },
                        { value: 'off', label: 'Off' },
                    ],
                },
                {
                    defaultValue: 'any',
                    key: 'validation.status',
                    handler: (v) => {
                        if (v !== 'any') return v;
                        return null;
                    },
                    label: 'Validation Status',
                    type: 'dropdown',
                    options: [
                        { value: 'any', label: 'Any' },
                        { value: 'done', label: 'Done' },
                        { value: 'pending', label: 'Pending' },
                    ],
                },
                {
                    defaultValue: null,
                    key: 'validation.last_check_at|gt',
                    label: 'Validated at - Since',
                    type: 'datepicker',
                },
                {
                    defaultValue: null,
                    key: 'validation.last_check_at|lt',
                    label: 'Validated at - Until',
                    type: 'datepicker',
                },
                {
                    defaultValue: [],
                    key: 'source|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Sources',
                },
                {
                    defaultValue: null,
                    key: 'captured_at|gt',
                    label: 'Captured at - Since',
                    type: 'datepicker',
                },
                {
                    defaultValue: null,
                    key: 'captured_at|lt',
                    label: 'Captured at - Until',
                    type: 'datepicker',
                },
                {
                    defaultValue: [],
                    key: 'projects@oid',
                    label: 'Projects',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'custom',
                    customElement: (v, onChange) => (
                        <ProjectsDropdown value={v} onChange={onChange} />
                    ),
                },
            ]}
            countColumnOptions={{
                header : 'Links',
            }}
        />
    );
};

//     const handleApplyFilters = () => {

//         if (filters.projects.length > 0)
//             final['projects@oid'] = filters.projects.join(',');
//         setAppliedFilters(final);
//     };

//     const generateCsv = () => {
//         if (!appliedFilters || !linkCountQuery.data) return;
//         if (linkCountQuery.data.length === 0) return;
//         const headers = Object.keys(linkCountQuery.data[0]);
//         const output = stringify(linkCountQuery.data, {
//             header: true,
//             columns: headers,
//         });
//         const blob = new Blob([output]);
//         const fileDownloadUrl = URL.createObjectURL(blob);
//         downloadFromUrl(
//             fileDownloadUrl,
//             `titlereport_link_count_${new Date().toISOString()}`,
//             '.csv'
//         );
//         URL.revokeObjectURL(fileDownloadUrl);
//     };

//                 <CustomDatepicker
//                     label='Validated at - Since'
//                     value={filters.validatedAtSince}
//                     onChange={(d) =>
//                         setFilters((old) => ({
//                             ...old,
//                             validatedAtSince: d,
//                         }))
//                     }
//                 />
//                 <CustomDatepicker
//                     label='Validated at - Until'
//                     value={filters.validatedAtUntil}
//                     onChange={(d) =>
//                         setFilters((old) => ({
//                             ...old,
//                             validatedAtUntil: d,
//                         }))
//                     }
//                 />
//                 <CustomChips
//                     addOnBlur
//                     allowDuplicate={false}
//                     label='Sources'
//                     value={filters.sources}
//                     onChange={(e) =>
//                         setFilters((old) => ({
//                             ...old,
//                             sources: e.target.value,
//                         }))
//                     }
//                 />
//                 <CustomDatepicker
//                     label='Captured at - Since'
//                     value={filters.capturedAtSince}
//                     onChange={(d) =>
//                         setFilters((old) => ({
//                             ...old,
//                             capturedAtSince: d,
//                         }))
//                     }
//                 />
//                 <CustomDatepicker
//                     label='Captured at - Until'
//                     value={filters.capturedAtUntil}
//                     onChange={(d) =>
//                         setFilters((old) => ({
//                             ...old,
//                             capturedAtUntil: d,
//                         }))
//                     }
//                 />
//                 <ProjectsDropdown
//                     value={filters.projects}
//                     onChange={(v) =>
//                         setFilters((old) => ({ ...old, projects: v }))
//                     }
//                 />
//                 <Button label='Generate metrics' onClick={handleApplyFilters} />
//             </div>
//             {(linkCountQuery.isFetching || linkCountQuery.isSuccess) && (
//                 <>
//                     <h3>
//                         {linkCountQuery.isFetching
//                             ? 'Loading count...'
//                             : 'Total count: ' +
//                               linkCountQuery.data
//                                   ?.map((a) => a.count)
//                                   .reduce((a, b) => a + b, 0)}
//                     </h3>
//                     {appliedFilters?.group_by && (
//                         <>
//                             <PaginatorWrap />
//                             <DataTable
//                                 loading={linkCountQuery.isFetching}
//                                 value={linkCountQuery.data}
//                                 removableSort
//                                 paginator
//                                 rows={pageOptions.rows}
//                                 first={first}
//                                 onPage={() => {}}
//                                 paginatorTemplate={''}
//                             >
//                                 <Column field='count' header='Count' sortable />
//                             </DataTable>
//                             <PaginatorWrap hideExport />
//                         </>
//                     )}
//                 </>
//             )}
//         </div>
//     );
// };

export default LinkCount;
