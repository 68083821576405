import api from 'services/api';

const apiUrl = window.API_URL;

export const listInboxRules = (options?: { signal?: AbortSignal }) => {
    return new Promise<Ether.TitlesReport.IInboxRules>((resolve, reject) => {
        api.get<{
            payload: Ether.TitlesReport.IInboxRules;
        }>(`${apiUrl}/list-inbox-rules`, {
            signal: options?.signal,
        })
            .then((result) => resolve(result.data.payload))
            .catch(reject);
    });
};

export const getInboxRule = (
    name: string,
    options?: { signal?: AbortSignal }
) => {
    return new Promise<Ether.TitlesReport.IInboxRules['rules'][0] | null>(
        (resolve, reject) => {
            api.get<{
                payload: Ether.TitlesReport.IInboxRules;
            }>(`${apiUrl}/list-inbox-rules`, {
                signal: options?.signal,
            })
                .then((result) => {
                    const data = result.data.payload.rules.find(
                        (r) => r.name === name
                    );
                    resolve(data ?? null);
                })
                .catch(reject);
        }
    );
};

export const createInboxRule = (
    data: Ether.TitlesReport.IInboxRules['rules'][0],
    options?: { signal?: AbortSignal }
) => {
    return new Promise<Ether.TitlesReport.IInboxRules['rules'][0]>(
        (resolve, reject) => {
            api.post<{
                payload: Ether.TitlesReport.IInboxRules['rules'][0];
            }>(`${apiUrl}/create-inbox-rules`, data, {
                signal: options?.signal,
            })
                .then((result) => resolve(result.data.payload))
                .catch(reject);
        }
    );
};

export const updateInboxRule = (
    name : string,
    data: Omit<Ether.TitlesReport.IInboxRules['rules'][0], 'name'>,
    options?: { signal?: AbortSignal }
) => {
    return new Promise<Ether.TitlesReport.IInboxRules['rules'][0]>(
        (resolve, reject) => {
            api.post<{
                payload: Ether.TitlesReport.IInboxRules['rules'][0];
            }>(`${apiUrl}/update-inbox-rules/${name}`, data, {
                signal: options?.signal,
            })
                .then((result) => resolve(result.data.payload))
                .catch(reject);
        }
    );
};

export const deleteInboxRule = (
    name: string,
    options?: { signal?: AbortSignal }
) => {
    return new Promise<boolean>((resolve, reject) => {
        api.delete<{
            payload: boolean;
        }>(`${apiUrl}/delete-inbox-rules/${name}`, {
            signal: options?.signal,
        })
            .then((result) => resolve(result.data.payload))
            .catch(reject);
    });
};
