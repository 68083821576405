import { Badge } from 'primereact/badge';

import DateBadge from 'components/Misc/DateBadge';

import { countLinkInbox } from 'services/title-report/link-inbox';

import './style.css';
import GenericCountScreen from '../../components/GenericCountScreen';
import ProjectsDropdown from '../../components/ProjectsDropdown';

const LinkInboxCount = () => {
    return (
        <GenericCountScreen
            cacheKey='linkInbox'
            title='Inbox count'
            groupByOptions={[
                {
                    label: 'Captured at',
                    value: 'captured_at|date',
                    body: (row) => {
                        if (!row['captured_at|date'])
                            return <Badge value='null' />;
                        return (
                            <DateBadge
                                hideTooltip
                                value={new Date(row['captured_at|date'])}
                            />
                        );
                    },
                },
                {
                    label: 'Domain',
                    value: 'domain',
                },
                {
                    label: 'Rejected tags',
                    value: 'rejected_tags',
                },
                {
                    label: 'Source',
                    value: 'source',
                },
                {
                    label: 'Title slug',
                    value: 'titleinfo.slug',
                    field: 'titleinfo_slug',
                    isDefault : true,
                },
            ]}
            fetchDataCountFn={countLinkInbox}
            filterHandlers={[
                {
                    defaultValue: [],
                    key: 'titleinfo.slug|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Title slugs',
                },
                {
                    defaultValue: [],
                    key: 'domain|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Domains',
                },
                {
                    defaultValue: [],
                    key: 'rejected_tags|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Rejected tags',
                },
                {
                    defaultValue: [],
                    key: 'source|in',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'chips',
                    label: 'Sources',
                },
                {
                    defaultValue: null,
                    key: 'captured_at|gt',
                    label: 'Captured at - Since',
                    type: 'datepicker',
                },
                {
                    defaultValue: null,
                    key: 'captured_at|lt',
                    label: 'Captured at - Until',
                    type: 'datepicker',
                },
                {
                    defaultValue: [],
                    key: 'projects@oid',
                    label: 'Projects',
                    handler: (v: string[]) => {
                        if (v.length > 0) return v.join(',');
                        return null;
                    },
                    type: 'custom',
                    customElement: (v, onChange) => (
                        <ProjectsDropdown value={v} onChange={onChange} />
                    ),
                },
            ]}
            countColumnOptions={{
                header : 'Inboxes',
            }}
        />
    );
};

// const LinkInboxCount = () => {
//     const [pageOptions, setPageOptions] = useState({
//         page: 1,
//         rows: 50,
//     });
//     const [first, setFirst] = useState(
//         (pageOptions.page - 1) * pageOptions.rows
//     );

//     useEffect(() => {
//         setFirst((pageOptions.page - 1) * pageOptions.rows);
//     }, [pageOptions]);

//     const [filters, setFilters] = useState<
//         TitleReportApp.CountFilters['linkInbox']
//     >(() => {
//         const defaultFilters: TitleReportApp.CountFilters['linkInbox'] = {
//             titleSlugs: [],
//             groupBy: ['titleinfo.slug'],
//             sources: [],
//             capturedAtSince: null,
//             capturedAtUntil: null,
//             projects: [],
//             rejectedTags : [],
//         };
//         const cacheData = CacheControl.CountFilters.get('linkInbox');
//         if (!cacheData) return defaultFilters;

//         const f: { [key: string]: any } = {};
//         Object.keys(defaultFilters as any).forEach(
//             (k) => (f[k] = cacheData[k] ?? (defaultFilters as any)[k])
//         );
//         return f as TitleReportApp.CountFilters['linkInbox'];
//     });

//     useEffect(() => CacheControl.CountFilters.save('linkInbox', filters), [filters]);

//     const [appliedFilters, setAppliedFilters] =
//         useState<ILinkInboxCountFilters | null>(null);

//     const projectsQuery = useQuery(['list-projects'], () =>
//         listProjects({
//             limit: 9999,
//             fields: ['name'],
//         })
//     );

//     const linkInboxCountQuery = useQuery(
//         ['link-inbox-count', appliedFilters],
//         async () => {
//             const links = await countLinkInbox({
//                 filters: appliedFilters ?? {},
//             });
//             return links;
//         },
//         {
//             enabled: !!appliedFilters,
//         }
//     );

//     const handleApplyFilters = () => {
//         const final: ILinkInboxCountFilters = {
//             group_by: filters.groupBy.join(','),
//         };
//         if (final.group_by === '') delete final.group_by;
//         if (filters.titleSlugs.length > 0)
//             final['titleinfo.slug|in'] = filters.titleSlugs.join(',');
//         if (filters.sources.length > 0)
//             final['sources|in'] = filters.sources.join(',');
//         if (filters.capturedAtSince)
//             final['captured_at|gt'] = filters.capturedAtSince;
//         if (filters.capturedAtUntil)
//             final['captured_at|lt'] = filters.capturedAtUntil;
//         if (filters.projects.length > 0)
//             final['projects@oid'] = filters.projects.join(',');
//         if (filters.rejectedTags.length > 0)
//             final['rejected_tags|in'] = filters.rejectedTags.join(',');
//         setAppliedFilters(final);
//     };

//     const generateCsv = () => {
//         if (!appliedFilters || !linkInboxCountQuery.data) return;
//         if (linkInboxCountQuery.data.length === 0) return;
//         const headers = Object.keys(linkInboxCountQuery.data[0]);
//         const output = stringify(linkInboxCountQuery.data, {
//             header: true,
//             columns: headers,
//         });
//         const blob = new Blob([output]);
//         const fileDownloadUrl = URL.createObjectURL(blob);
//         downloadFromUrl(
//             fileDownloadUrl,
//             `titlereport_inbox_count_${new Date().toISOString()}`,
//             '.csv'
//         );
//         URL.revokeObjectURL(fileDownloadUrl);
//     };

//     const PaginatorWrap: React.FC<{ hideExport?: boolean }> = ({
//         hideExport,
//     }) => {
//         return (
//             <div
//                 style={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     gap: '16px',
//                 }}
//             >
//                 <NoTotalPaginator
//                     page={pageOptions.page}
//                     rows={pageOptions.rows}
//                     maxPages={
//                         linkInboxCountQuery.data &&
//                         linkInboxCountQuery.data.length !== 0
//                             ? Math.ceil(
//                                   linkInboxCountQuery.data.length /
//                                       pageOptions.rows
//                               )
//                             : 1
//                     }
//                     onPageChange={setPageOptions}
//                     showRefresh
//                     onRefresh={linkInboxCountQuery.refetch}
//                     disableNext={
//                         linkInboxCountQuery.data &&
//                         pageOptions.page * pageOptions.rows >=
//                             linkInboxCountQuery.data?.length
//                     }
//                     rowsPerPageOptions={[10, 50, 100, 500]}
//                 />
//                 {!hideExport && (
//                     <Button
//                         icon='pi pi-download'
//                         label='Export CSV'
//                         onClick={generateCsv}
//                         disabled={
//                             !linkInboxCountQuery.data ||
//                             linkInboxCountQuery.data.length === 0
//                         }
//                     />
//                 )}
//             </div>
//         );
//     };

//     return (
//         <div>
//             <Link to='..'>
//                 <Button label='Back' />
//             </Link>
//             <h2>Inbox count</h2>
//             <div className='filter-group'>
//                 <CustomChips
//                     addOnBlur
//                     allowDuplicate={false}
//                     label='Title Slugs'
//                     value={filters.titleSlugs}
//                     onChange={(e) =>
//                         setFilters((old) => ({
//                             ...old,
//                             titleSlugs: e.target.value,
//                         }))
//                     }
//                 />
//                 <CustomChips
//                     addOnBlur
//                     allowDuplicate={false}
//                     label='Rejected tags'
//                     value={filters.rejectedTags}
//                     onChange={(e) =>
//                         setFilters((old) => ({
//                             ...old,
//                             rejectedTags: e.target.value,
//                         }))
//                     }
//                 />
//                 <CustomChips
//                     addOnBlur
//                     allowDuplicate={false}
//                     label='Sources'
//                     value={filters.sources}
//                     onChange={(e) =>
//                         setFilters((old) => ({
//                             ...old,
//                             sources: e.target.value,
//                         }))
//                     }
//                 />
//                 <CustomMultiSelect
//                     label='Group by'
//                     value={filters.groupBy}
//                     onChange={(e) =>
//                         setFilters((old) => ({
//                             ...old,
//                             groupBy: e.target.value,
//                         }))
//                     }
//                     display={
//                         (filters.groupBy?.length ?? 0) <= 3 ? 'chip' : 'comma'
//                     }
//                     maxSelectedLabels={3}
//                     options={[
//                         { label: 'Captured at', value: 'captured_at|date' },
//                         {
//                             label: 'Rejected tags',
//                             value: 'rejected_tags',
//                         },
//                         { label: 'Source', value: 'source' },
//                         { label: 'Title slug', value: 'titleinfo.slug' },
//                     ]}
//                 />
//                 <CustomDatepicker
//                     label='Captured at - Since'
//                     value={filters.capturedAtSince}
//                     onChange={(d) =>
//                         setFilters((old) => ({
//                             ...old,
//                             capturedAtSince: d,
//                         }))
//                     }
//                 />
//                 <CustomDatepicker
//                     label='Captured at - Until'
//                     value={filters.capturedAtUntil}
//                     onChange={(d) =>
//                         setFilters((old) => ({
//                             ...old,
//                             capturedAtUntil: d,
//                         }))
//                     }
//                 />
//                 <CustomMultiSelect
//                     label='Projects'
//                     value={filters.projects}
//                     onChange={(e) =>
//                         setFilters((old) => ({
//                             ...old,
//                             projects: e.target.value,
//                         }))
//                     }
//                     display={
//                         (filters.projects?.length ?? 0) <= 2 ? 'chip' : 'comma'
//                     }
//                     maxSelectedLabels={2}
//                     loading={projectsQuery.isLoading}
//                     options={projectsQuery.data}
//                     optionValue='_id'
//                     optionLabel='name'
//                 />
//                 <Button label='Generate metrics' onClick={handleApplyFilters} />
//             </div>
//             {(linkInboxCountQuery.isFetching ||
//                 linkInboxCountQuery.isSuccess) && (
//                 <>
//                     <h3>
//                         {linkInboxCountQuery.isFetching
//                             ? 'Loading count...'
//                             : 'Total count: ' +
//                               linkInboxCountQuery.data
//                                   ?.map((a) => a.count)
//                                   .reduce((a, b) => a + b, 0)}
//                     </h3>
//                     {appliedFilters?.group_by && (
//                         <>
//                             <PaginatorWrap />
//                             <DataTable
//                                 loading={linkInboxCountQuery.isFetching}
//                                 value={linkInboxCountQuery.data}
//                                 removableSort
//                                 paginator
//                                 rows={pageOptions.rows}
//                                 first={first}
//                                 onPage={() => {}}
//                                 paginatorTemplate={''}
//                             >
//                                 {appliedFilters?.group_by?.includes(
//                                     'titleinfo.slug'
//                                 ) && (
//                                     <Column
//                                         field='titleinfo_slug'
//                                         header='Title Slug'
//                                         sortable
//                                     />
//                                 )}
//                                 {appliedFilters?.group_by?.includes(
//                                     'source'
//                                 ) && (
//                                     <Column
//                                         field='source'
//                                         header='Source'
//                                         sortable
//                                     />
//                                 )}
//                                 {appliedFilters?.group_by?.includes(
//                                     'rejected_tags'
//                                 ) && (
//                                     <Column
//                                         field='rejected_tags'
//                                         header='Rejected tags'
//                                         sortable
//                                     />
//                                 )}
//                                 {appliedFilters?.group_by?.includes(
//                                     'captured_at|date'
//                                 ) && (
//                                     <Column
//                                         field='captured_at|date'
//                                         header='Captured at'
//                                         body={(row) => {
//                                             if (!row['captured_at|date'])
//                                                 return <Badge value='null' />;
//                                             return (
//                                                 <DateBadge
//                                                     hideTooltip
//                                                     value={
//                                                         new Date(
//                                                             row[
//                                                                 'captured_at|date'
//                                                             ]
//                                                         )
//                                                     }
//                                                 />
//                                             );
//                                         }}
//                                         sortable
//                                         filter
//                                     />
//                                 )}
//                                 <Column field='count' header='Count' sortable />
//                             </DataTable>
//                             <PaginatorWrap hideExport />
//                         </>
//                     )}
//                 </>
//             )}
//         </div>
//     );
// };

export default LinkInboxCount;
