import axios from 'axios';
import { EJSON, ObjectId } from 'bson';

const instance = axios.create({
    timeout: 30000,
});

instance.interceptors.request.use(
    (req) => {
        return req;
    },
    (err) => {
        console.error(err);
    }
);

export function handleOid(body: any) {
    if (body === null || body === undefined || typeof body !== 'object')
        return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (value instanceof ObjectId) {
            body[key] = value.toHexString();
        } else if (typeof value === 'object') handleOid(value);
    }
    return body;
}

instance.interceptors.response.use(
    (res) => {
        res.data = EJSON.deserialize(res.data as any) as any;
        res.data = handleOid(res.data);
        if (res.data.success != null && !res.data.success)
            throw new Error(res.data.error);
        return res;
    },
    (err) => {
        if (err.response) {
            if (typeof err.response?.data === 'string')
                throw new Error('Server error: ' + err.response.data);
            else if (typeof err.response?.data?.error === 'string')
                throw new Error('Server error: ' + err.response.data.error);
            throw new Error('Unknown server error');
        } else {
            if (err.message === 'Network Error')
                err.message = 'Failed to connect';
            throw err;
        }
    }
);

export default instance;
