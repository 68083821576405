import { ProgressBar } from 'primereact/progressbar';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getOneReport } from 'services/title-report/report';

import './style.css';

interface IReportProgressUpdater {
    report: Ether.TitlesReport.IReport;
    projectId: string;
    onReportSettled(): void;
}

const ReportProgressUpdater: React.FC<IReportProgressUpdater> = ({
    report,
    projectId,
    onReportSettled,
}) => {
    const [status, setStatus] = useState<string>(report.status);
    const [progress, setProgress] = useState(report.progress);

    useQuery(
        ['report-progress', report._id],
        () =>
            getOneReport(report._id, projectId, {
                fields: ['progress', 'status'],
            }),
        {
            refetchInterval: 5000,
            refetchIntervalInBackground: true,
            enabled: status === 'processing' || status === 'new',
            onSuccess: (data) => {
                if (data) {
                    if (data.status !== status) onReportSettled();
                    setStatus(data.status);
                    setProgress(data.progress);
                }
            },
        }
    );

    useEffect(() => {
        setStatus(report.status);
        setProgress(report.progress);
    }, [report]);

    return (
        <ProgressBar
            value={Math.round(parseFloat(progress) * 100) / 100}
            // displayValueTemplate={() => 'asdf'}
        />
    );
};

export default ReportProgressUpdater;
