import { Outlet } from 'react-router-dom';

import Navbar from 'components/Page/Navbar';
import PageWrap from 'components/Page/PageWrap';
import ContentWrap from 'components/Page/ContentWrap';
import { Button } from 'primereact/button';
import { useAuth } from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import { getMyInfo } from 'services/title-report/me';
import { ToastProvider } from 'hooks/useToast';

const Main = () => {
    const { signOut } = useAuth();

    const myInfo = useQuery<Ether.IMe, Error>(
        ['me'],
        (): Promise<Ether.IMe> => getMyInfo()
    );

    return (
        <PageWrap>
            <Navbar
                navigations={[
                    {
                        label: 'Home',
                        path: '/',
                    },
                    {
                        label: 'Projects',
                        path: '/projects',
                    },
                    ...(myInfo.data?.is_superadmin
                        ? [
                              {
                                  label: 'Inbox Rules',
                                  path: '/inbox-rules',
                              },
                              {
                                  label: 'Metrics',
                                  path: '/metrics',
                              },
                          ]
                        : []),
                    {
                        label: 'Settings',
                        path: '/settings',
                    },
                ]}
                endContent={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button label='Sign out' onClick={signOut} />
                    </div>
                }
            />
            <ToastProvider>
                <ContentWrap>
                    <Outlet />
                </ContentWrap>
            </ToastProvider>
        </PageWrap>
    );
};

export default Main;
