import CacheControl from 'controller/cache/cacheController';
import { Checkbox } from 'primereact/checkbox';
import { useEffect, useState } from 'react';

const Settings = () => {
    const [config, setConfig] = useState<TitleReportApp.UserConfig>(() => {
        const value = CacheControl.UserConfig.get();
        if (value) {
            return value;
        }
        return {
            autoHideLink: true,
        };
    });

    useEffect(() => {
        CacheControl.UserConfig.save(config);
    }, [config]);

    return (
        <div>
            <h1>Settings</h1>
            <div>
                <h3>Auto-hide link on validation?</h3>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Checkbox
                        checked={config.autoHideLink}
                        onChange={(e) =>
                            setConfig((old) => ({
                                ...old,
                                autoHideLink: e?.checked ?? false,
                            }))
                        }
                    />
                    <label>{config.autoHideLink ? 'Yes' : 'No'}</label>
                </div>
            </div>
        </div>
    );
};

export default Settings;
