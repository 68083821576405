import {
    DataTableFilterMeta,
    DataTableFilterMetaData,
    DataTableOperatorFilterMetaData,
    DataTableSortMeta,
} from 'primereact/datatable';
import api from 'services/api';
import { handleFilterMatchMode } from 'services/utils';
import { exportAsCsv } from 'utils/csvHandler';

const apiUrl = window.API_URL;

// export type IFilterOnOffActive = 'on' | 'off' | 'unknown' | 'any';
// export type IFilterOnOffStatus = Ether.TitlesReport.OnOffStatus | 'any';
// export type IFilterValidation = 'validated' | 'not-validated' | 'any';

const handleDataTableFilterMeta = (filters?: DataTableFilterMeta) => {
    if (!filters) return {};
    const extractValueAndMatchMode = (
        filterMeta: DataTableFilterMetaData | DataTableOperatorFilterMetaData
    ) => {
        if (!filterMeta) return { value: undefined, matchMode: undefined };
        if ('value' in filterMeta)
            return {
                value: filterMeta.value,
                matchMode: filterMeta.matchMode,
            };
        return {
            value: filterMeta.constraints[0].value,
            matchMode: filterMeta.constraints[0].matchMode,
        };
    };
    const handleField = (
        key: string,
        params: { [key: string]: string | number }
    ) => {
        const { value, matchMode } = extractValueAndMatchMode(filters[key]);
        if (value == null || value === '') return params;
        return {
            ...params,
            ...handleFilterMatchMode(key, value, matchMode),
        };
    };

    let paramFilters: { [key: string]: string | number } = {};

    [
        '_id',
        'value',
        'source',
        'source_type',
        'titleinfo.slug',
        'referer',
        'videoid_data.validation.checked_at',
        'videoid_data.match_data.score',
        'videoid_data.is_match',
        'videoid_data.validation.is_valid',
        'videoid_data.validation.status',
    ].forEach((k) => {
        paramFilters = handleField(k, paramFilters);
    });

    if (filters['validation']) {
        const { value } = extractValueAndMatchMode(filters['validation']);
        if (value && value !== 'any')
            paramFilters['validation.status|in'] =
                value === 'validated' ? 'done' : 'sent,pending';
    }
    if (filters['onoff_active']) {
        const { value } = extractValueAndMatchMode(filters['onoff_active']);
        if (value && value !== 'any' && value !== 'unknown') {
            paramFilters['onoff_data.is_active@bool'] =
                value === 'on' ? 'true' : 'false';
        }
        if (value === 'unknown')
            paramFilters['onoff_data.is_active|isnull'] = 'true';
        else if (value === 'on' || value === 'false')
            paramFilters['onoff_data.is_active|isnull'] = 'false';
    }
    if (filters['onoff_status']) {
        const { value } = extractValueAndMatchMode(filters['onoff_status']);
        if (value && value !== 'any') paramFilters['onoff_data.status'] = value;
    }
    return paramFilters;
};

export const getLinksFromProject = (
    project_id: string,
    options?: {
        signal?: AbortSignal;
        limit?: number;
        offset?: number;
        filters?: DataTableFilterMeta;
        sortField?: string | null;
        sortOrder?: DataTableSortMeta['order'] | null;
    }
): Promise<Ether.TitlesReport.ILink[]> => {
    return new Promise<Ether.TitlesReport.ILink[]>((resolve, reject) => {
        const filters = handleDataTableFilterMeta(options?.filters);

        let order: string | null = null;
        if (options?.sortField && options.sortOrder) {
            if (options.sortOrder === 1) {
                order = options.sortField;
            } else if (options.sortOrder === -1) {
                order = '-' + options.sortField;
            }
        }
        if (order) filters['order'] = order;

        api.get<Ether.IApi<any>>(`${apiUrl}/list-link`, {
            signal: options?.signal,
            params: {
                'projects@oid': project_id,
                limit: options?.limit ? options.limit : 10,
                offset: options?.offset,
                ...filters,
            },
        })
            .then((result) => {
                const links = result.data.payload;
                resolve(links);
            })
            .catch(reject);
    });
};

export const countLinks = <T>(options?: {
    signal?: AbortSignal;
    filters?: { [key: string]: any };
}) => {
    return new Promise<(Partial<T> & { count: number })[]>(
        (resolve, reject) => {
            api.get<{
                success: string;
                meta: { payload_count: number; [key: string]: unknown };
                payload: (Partial<T> & { count: number })[] | number;
            }>(`${apiUrl}/count-link`, {
                signal: options?.signal,
                params: {
                    ...options?.filters,
                },
                timeout: 15 * 60 * 1000,
            })
                .then((result) => {
                    const data = result.data.payload;
                    if (typeof data === 'number') {
                        resolve([
                            {
                                count: data,
                            } as any,
                        ]);
                    } else {
                        resolve(data);
                    }
                })
                .catch(reject);
        }
    );
};

export const getNextLinkToValidateVideoID = (
    project_id: string,
    options?: {
        signal?: AbortSignal;
        filters?: {
            titledb_slug?: string;
            videoid_min_score?: number;
            videoid_max_score?: number;
        };
    }
): Promise<Ether.TitlesReport.ILink | null> => {
    return new Promise<Ether.TitlesReport.ILink | null>((resolve, reject) => {
        api.get<Ether.IApi<any>>(`${apiUrl}/get-links-to-validate-videoid`, {
            signal: options?.signal,
            params: {
                project_oid: project_id,
                slug:
                    options?.filters?.titledb_slug !== ''
                        ? options?.filters?.titledb_slug
                        : undefined,
                videoid_min_score: options?.filters?.videoid_min_score,
                videoid_max_score: options?.filters?.videoid_max_score,
                limit: 1,
            },
        })
            .then((result) => {
                const count = result.data.payload.length;
                if (count === 1) {
                    const link = result.data.payload[0];
                    resolve(link);
                } else {
                    resolve(null);
                }
            })
            .catch(reject);
    });
};

export const validateLink = (
    link_id: string,
    accepted: boolean | null,
    options?: {
        signal?: AbortSignal;
    }
): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        api.post(
            `${apiUrl}/validate-link`,
            [
                {
                    link_oid: link_id,
                    accepted: accepted,
                    description: '',
                },
            ],
            {
                signal: options?.signal,
            }
        )
            .then(() => resolve(true))
            .catch(reject);
    });
};

export const validateVideoIdCheck = (
    link_id: string,
    is_match: boolean,
    options?: {
        signal?: AbortSignal;
    }
): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        api.post(
            `${apiUrl}/validate-videoid-check`,
            [
                {
                    link_oid: link_id,
                    is_match: is_match,
                },
            ],
            {
                signal: options?.signal,
            }
        )
            .then(() => resolve(true))
            .catch(reject);
    });
};

export const exportLinksWithVideoInfo = async (
    projectId: string,
    options?: {
        filters?: DataTableFilterMeta;
    }
) => {
    const getValidEvaluation = (rowData: Ether.TitlesReport.ILink) => {
        if (rowData.videoid_data.validation.is_valid == null) return null;
        const { is_match } = rowData.videoid_data;
        const { is_valid } = rowData.videoid_data.validation;
        if (is_match) return is_valid ? 'true positive' : 'false positive';
        return is_valid ? 'true negative' : 'false negative';
    };

    const fetchLinks = (filters: { [key: string]: string | number }) => {
        return new Promise<any[]>((resolve, reject) => {
            api.get<Ether.IApi<any>>(`${apiUrl}/list-link`, {
                params: {
                    project_oid: projectId,
                    ...filters,
                },
            })
                .then((result) => {
                    const links = result.data.payload;
                    resolve(
                        links.map((l) => ({
                            ...l,
                            videoid_data: {
                                ...l.videoid_data,
                                validation_result: getValidEvaluation(l),
                            },
                        }))
                    );
                })
                .catch(reject);
        });
    };

    const csvHeaders: (
        | string
        | {
              field: string;
              name?: string;
              parseFunction?(value: any): string;
          }
    )[] = [
        '_id',
        'source',
        'source_type',
        'titleinfo.slug',
        'titleinfo.season',
        'titleinfo.episode',
        'videoid_data.match_data.score',
        {
            field: 'videoid_data.is_match',
            name: 'videoid_data.is_match',
            parseFunction: (value) =>
                value == null ? 'null' : value ? 'true' : 'false',
        },
        'videoid_data.validation_result',
        'videoid_data.validation.status',
        {
            field: 'videoid_data.validation.is_valid',
            name: 'videoid_data.validation.is_valid',
            parseFunction: (value) =>
                value == null ? 'null' : value ? 'true' : 'false',
        },
        'videoid_data.validation.checked_at',
        'videoid_data.validation.checked_by',
        'created_at',
        'updated_at',
    ];

    const filters = handleDataTableFilterMeta(options?.filters);

    await exportAsCsv({
        filename: 'titlereportv2_' + projectId + '_videoid_macthes',
        fetchFn: fetchLinks,
        filters,
        csvHeaders,
    });
};
