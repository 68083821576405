import CustomMultiSelect from 'components/Form/CustomMultiSelect';
import { useQuery } from '@tanstack/react-query';
import { listProjects } from 'services/title-report/project';

const ProjectsDropdown: React.FC<{
    value: string[];
    onChange(v: string[]): void;
}> = ({ value, onChange }) => {
    const projectsQuery = useQuery(['list-projects'], () =>
        listProjects({
            limit: 9999,
            fields: ['name'],
        })
    );

    return (
        <CustomMultiSelect
            label='Projects'
            value={value}
            onChange={(e) => onChange(e.target.value)}
            display={(value.length ?? 0) <= 2 ? 'chip' : 'comma'}
            maxSelectedLabels={2}
            loading={projectsQuery.isLoading}
            options={projectsQuery.data}
            optionValue='_id'
            optionLabel='name'
        />
    );
};

export default ProjectsDropdown;
